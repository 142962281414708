import { useEffect, useState } from "react"
import { fmtMoney } from "../functions/generalFunctions"
import api from '../components/api'
import InputMask from 'react-input-mask'
import moment from 'moment'
import { textAlign } from "@mui/system"


const Modal = (props) => {


    const entrega_id = props.entrega_id

    
    const [dataEmissao, setDataEmissao] = useState('')
    const [empenho, setEmpenho] = useState('')
    const [quantidade, setQuantidade] = useState(0)
    const [valorUnit, setValorUnit] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [numeroProtocolo, setNumeroProtocolo] = useState('')
    const [modalidade, setModalidade] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [fornecedor, setFornecedor] = useState('')
    const [siafisicoPregao, setSiafisicoPregao] = useState('')    
    const [descricao, setDescricao] = useState('')
    const [item_id, setItem_id] = useState('')

    const [quantidadeRecebida, setQuantidadeRecebida] = useState('')


    const [dataEntrega, setDataEntrega] = useState('')
    const [observacao, setObservacao] = useState('')

    const [resultadoEntregas, setResultadoEntregas] = useState()

    


    const salvar = () => {


        if (quantidadeRecebida == '') {

			window.toastr.error('<b>Quantidade Recebida</b> não pode ficar em branco!');
			return false;

		}

        if (moment(dataEntrega, "DD/MM/YYYY", true).isValid() == false) {

			window.toastr.error('<b>Data de entrega</b> inv&aacute;lida!');
			return false;

		}

        var dataEntrega_array = dataEntrega.split('/')
        var dataEntrega_msk = `${dataEntrega_array[2]}-${dataEntrega_array[1]}-${dataEntrega_array[0]}`


        var dataPost = {

            entrega_id: entrega_id,
            item_id: item_id,
            quantidade: quantidadeRecebida,
            data: dataEntrega_msk,
            observacao: observacao,
            recebidoPor: sessionStorage.getItem('nome'),

        }

        //console.log(dataPost)


                    
        api.post(`entrega`, dataPost).then((result) => {

            //console.log(result.data)
            
            //window.toastr.success('Salvo com sucesso!')
            //carregaEntregas()
            
            setQuantidadeRecebida('')
            setObservacao('')
            setDataEntrega('')

            props.carregaEntregas()
            props.carregaEmpenhos()

        }).catch((err) => {

            console.log(err.response)

        })

        

        

        
    }



    const carrega = () => {

        if (entrega_id) {

            api.get(`empenho/${entrega_id}`).then((result) => {

                const data = result.data[0]
                
                setEmpenho(data.empenho)
                setDataEmissao(data.dataEmissao)
                setQuantidade(data.quantidade)
                setValorUnit(fmtMoney(data.valorUnit, 2).replaceAll('R$', ''))
                setValorTotal(fmtMoney(data.valorTotal, 2).replaceAll('R$', ''))
                setUnidFornecimento(data.unidFornecimento)
                setNumeroProtocolo(data.numeroProtocolo)
                setModalidade(data.modalidade)
                setCnpj(data.cnpj)
                setFornecedor(data.fornecedor)
                setSiafisicoPregao(data.siafisicoPregao)                
                setDescricao(data.descricao)
                setItem_id(data.item_id)


            }).catch((err) => {

                console.log(err)

            })

        }

    }

    const apagar = (itemEntrega_id) => {


        api.delete(`entrega/${itemEntrega_id}`).then((result) => {

            console.log(result.data)
            //window.toastr.error('Apagado com sucesso!')
            //carregaEntregas()
            
            props.carregaEntregas()
            //props.carregaEmpenhos()

        }).catch((err) => {

            console.log(err.response)

        })


    }



    useEffect(() => {




    }, [entrega_id])


    return (

        <div>


                <div class="modal fade modal-EntregaItem"
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Entrega de item</h4>
                        </div>
                        <div class="modal-body">

                        <div className="row">


                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">NE</label>
                                        <input type="text" className="form-control" value={empenho} style={{ backgroundColor: '#ebd1d1', textTransform: 'uppercase'}} maxLength={12} onChange={event => setEmpenho(event.target.value)} disabled={true} />
                                    </fieldset>
                                </div>

                               

                               
                        </div>

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )


}

export default Modal