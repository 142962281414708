import { useState, useEffect } from 'react'
import io from 'socket.io-client'
import { removeDuplicates, fmtMoney } from "../functions/generalFunctions"
import moment from 'moment'
import api from '../components/api'
import { PieChart, Pie, Sector, Cell, BarChart, Bar, LabelList, Legend, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer  } from "recharts";

import ModalDetalhePieChart from '../modal/ModalDetalhePieChart'
import ModalDetalheBarChart from '../modal/ModalDetalheBarChart'


const renderCustomizedLabel = (props) => {
    //const { x, y, width, height, value } = props;
    const {x, y, fill, value} = props;
    const radius = 10;
  
    return (
      <g>
    
        <text 
           x={x} 
           y={y} 
           dy={-4} 
           fontSize='16' 
           fontFamily='sans-serif'
           fill={fill}  
           textAnchor="right">
            
           </text>
      </g>
    );
  };


  const CustomTooltip = ({ active, payload, label }) => {
    //console.log(payload)
    if (active && payload && payload.length) {
      return (
        <div className="customTooltip">
          <p>{(payload[0]?.payload?.name)}: <font color="brown">{(payload[0]?.payload?.total)} item(ns)</font> [{payload[0]?.payload?.porcentagem}%]<br/> <br/>             
          
          </p>
        </div>
      );
    }
  
    return null;
  };




  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#003366', '#d742f5', '#f54242', '#486360', '#42f554'];  

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel2 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };



const Home = () => {


    const [usuariosOn, setUsuariosOn] = useState()
    const [listaUsuariosOn, setListaUsuariosOn] = useState()

    const ano = moment().format('YYYY')


    const [metaConsumoTotal, setMetaConsumoTotal] = useState(0)
    const [metaCompraTotal, setMetaCompraTotal] = useState(0)
    const [totalEmProcesso, setTotalEmProcesso] = useState(0)
    const [totalEmpenhado, setTotalEmpenhado] = useState(0)

    const [totalQtdeEmProcesso, setTotalQtdeEmProcesso] = useState(0)
    const [totalQtdeEmpenhado, setTotalQtdeEmpenhado] = useState(0)

    const [totalPlanejadoEmProcesso, setTotalPlanejadoEmProcesso] = useState(0)
    const [totalNaoPlanejadoEmProcesso, setTotalNaoPlanejadoEmProcesso] = useState(0)

    const [totalPlanejadoEmpenhado, setTotalPlanejadoEmpenhado] = useState(0)
    const [totalNaoPlanejadoEmpenhado, setTotalNaoPlanejadoEmpenhado] = useState(0)

    const [totalNaoPlanejado, setTotalNaoPlanejado] = useState(0)
    const [totalNecessarioAno, setTotalNecessarioAno] = useState(0)

    const [resultadoAvaliacao, setResultadoAvaliacao] = useState()
    
    const [data, setData] = useState([])
    const [data2, setData2] = useState([])

    const [dadosGrafico, setDadosGrafico] = useState()

    const [tipoAutonomia, setTipoAutonomia] = useState('A')

    async function renderizaUsuarios() {

        if (listaUsuariosOn) {

            //console.log(listaUsuariosOn)

            setUsuariosOn(listaUsuariosOn.map((rs) => 

                <tr style={{ fontSize: '11px'}}>
                    <td>
                        {rs.nome}
                    </td>
                </tr>
                
            ))


            //dataTableRender('tabelaUsuariosOn')
            
        }

    }


    const carregaDashboard = () => {


        api.get('dashboardPlanejamento').then((result) => {

            var data = result.data[0][0]

            

            setMetaConsumoTotal(`${fmtMoney(data.metaConsumoTotal  / 1000000, 1)} mi`)
            setMetaCompraTotal(`${fmtMoney(data.metaCompraTotal / 1000000, 1)} mi`)
            setTotalEmProcesso(`${fmtMoney(data.totalEmProcesso / 1000000, 1)} mi`)
            setTotalEmpenhado(`${fmtMoney(data.totalEmpenhado / 1000000, 1)} mi`)

            setTotalQtdeEmProcesso(data.totalQtdeEmProcesso)
            setTotalQtdeEmpenhado(data.totalQtdeEmpenhado)

            setTotalPlanejadoEmProcesso(`${fmtMoney(data.totalPlanejadoEmProcesso / 1000000, 1)} mi`)
            setTotalNaoPlanejadoEmProcesso(`${fmtMoney(data.totalNaoPlanejadoEmProcesso / 1000000, 1)} mi`)

            setTotalPlanejadoEmpenhado(`${fmtMoney(data.totalPlanejadoEmpenhado / 1000000, 1)} mi`)
            setTotalNaoPlanejadoEmpenhado(`${fmtMoney(data.totalNaoPlanejadoEmpenhado / 1000000, 1)} mi`)

            setTotalNaoPlanejado(`${fmtMoney(data.totalNaoPlanejado / 1000000, 1)} mi`)
            setTotalNecessarioAno(`${fmtMoney(data.totalNecessarioAno / 1000000, 1)} mi`)


        }).catch((err) => {

            console.log(err)

        })


    }

    const carregaGrafico = () => {

        var dataPost = {

            ano: ano,
            nome: "Planejamento Consumo",
            total: false,

        }


        api.post('dadosGrafico', dataPost).then((result) => {
           

            setData2(result.data)
            
        }).catch((err) => {

            console.log(err.response)

        })
         


    }

    const carregaTabelaGrafico = () => {




        var dataPost = {

            ano: ano,
            nome: "Planejamento Consumo",
            total: true,

        }



        api.post('dadosGrafico', dataPost).then((result) => {
           

            setResultadoAvaliacao(result.data.map((rs) => 

            <tr>
            
                <td style={{ textAlign: 'left', fontWeight: rs.name == 'Total' ? 'bold' : 'normal' }}>
                    {rs.name}
                </td>
                <td style={{ textAlign: 'right', fontWeight: rs.name == 'Total' ? 'bold' : 'normal' }}>
                    {rs.total.toLocaleString('pt-BR')}
                </td>
            
            </tr>

        ))
            
        }).catch((err) => {

            console.log(err.response)

        })

        

    }


    const carregaGraficoAutonomia = () => {

        /*
        setData(result.data.map((rs) => {
               
            return {

                name: rs.fase,
                total: rs.total,
                //descricao: rs.descricao,
                //quantidade: rs.quantidade,
                

            }

                           

        }))

        */

        var dataPost = {

            tipo: tipoAutonomia,

        }

        api.post(`listaAutonomia/${ano}`, dataPost).then((result) => {

            setData(result.data.map((rs) => {
               
                return {
    
                    total: rs.itens,
                    name: rs.descricao,
                    porcentagem: rs.porcentagem.toLocaleString('pt-BR'),
                    //quantidade: rs.quantidade,
                    
    
                }
    
                               
    
            }))

        }).catch((err) => {

            console.log(err.response)

        })

       setData([
            {
                name: "Fase 1",
                total: 124,

            }
        ])


    }


    useEffect(() => {

        var socket = io(process.env.REACT_APP_API);
        //var socket = io('https://api-faj.koderia.com.br');

            

        var nome = sessionStorage.getItem('nome')
        var usuario_id = sessionStorage.getItem('usuario_id')

        
        
        /*
        socket.emit('news', { hello: 'world' });

        socket.on('message', function (message) {
            console.log(message);
        });

        */

        var dataPost = { 
            
            nome: nome, 
            usuario_id: usuario_id,
        
        }

        //console.log(dataPost)

        socket.emit('news', dataPost);

        /*
        socket.on('message', function (message) {
            //console.log(message, '!!');
        });
        */
       


        
          
        socket.on('usuariosOn', function (usuariosOn) {
            
            //console.log(usuariosOn.filter(onlyUnique), '!?')
            setListaUsuariosOn(removeDuplicates(usuariosOn, "nome"))
            
        });

        socket.on('previousMessages', function(message) {
            //console.log(message)
            /*
            var dataPost = {
                us_codigo: sessionStorage.getItem('us_codigo'),
                nome: sessionStorage.getItem('nome'),
            }
            message.push(dataPost)
            */
           //console.log('aqui')
           
            var existe = false

            for (var i = 0; i < message.length; i++) {

                if (message[i].us_codigo == sessionStorage.getItem('us_codigo')) {
                    var existe = true
                    break;
                }

            }
            
            if (!existe) {

                var dataPost = {
                    us_codigo: sessionStorage.getItem('us_codigo'),
                    nome: sessionStorage.getItem('nome'),
                }
                message.push(dataPost)

            }

            setListaUsuariosOn(removeDuplicates(message, "nome"))

        })
          

        carregaDashboard()
        carregaGrafico()
        carregaTabelaGrafico()
        
        
    }, [])


    useEffect(() => {

        carregaGraficoAutonomia()

    }, [tipoAutonomia])
    

    useEffect(() => {

        renderizaUsuarios()        

    }, [listaUsuariosOn])



    const detalhePieChart = (e) => {

        //console.log(e)
        setDadosGrafico(e)

        window.$('#modalDetalhePieChart').modal('show')

    }

    const detalheBarChart = (e) => {

        console.log(e)
        setDadosGrafico(e)
        window.$('#modalDetalheBarChart').modal('show')


    }
    


    return (

        <div>

            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                                Início
                            </li>
                            
                        </ul>
                    </div>	



                    <div className="col-xl-12" >
                        <div className="row">
                            <div className="col-sm-3">
                                <article className="statistic-box yellow">
                                    <div>
                                        <div className="number" style={{ fontSize: '35px' }}>{metaConsumoTotal}</div>
                                        <div className="caption"><div><font style={{ fontSize: '13px'}}>Planejamento Consumo {ano}</font></div></div>
                                        
                                    </div>
                                </article>
                            </div>
                            <div className="col-sm-3">
                                <article className="statistic-box purple">
                                    <div>
                                        <div className="number" style={{ fontSize: '35px' }}>{metaCompraTotal}</div>
                                        <div className="caption"><div><font style={{ fontSize: '13px'}}>Planejamento de Compras {ano}</font></div></div>
                                        
                                    </div>
                                </article>
                            </div>
                            <div className="col-sm-3">
                                <article className="statistic-box red">
                                    <div>
                                        <div className="number" style={{ fontSize: '35px' }}>{totalEmProcesso}</div>
                                        
                                        <div className="caption"><div><font style={{ fontSize: '13px'}}>{totalQtdeEmProcesso} processos em andamento</font></div></div>
                                        
                                    </div>
                                </article>
                            </div>
                            
                            
                            <div className="col-sm-3">
                                <article className="statistic-box green">
                                    <div>
                                        <div className="number" style={{ fontSize: '35px' }}>{totalEmpenhado}</div>
                                        <div className="caption"><div><font style={{ fontSize: '13px'}}>{totalQtdeEmpenhado} processos empenhados</font></div></div>
                                        
                                    </div>
                                </article>
                            </div>
                            
                            
                        </div>
                    </div>

                    <div className="col-xl-12" >
                        <div className="row">
                            <div className="col-sm-3">
                               
                            </div>
                            <div className="col-sm-3">

                                
                                <article className="statisticP-box purple2">
                                    <div>
                                        <div className="number" style={{ fontSize: '35px' }}>{totalNecessarioAno}</div>
                                        
                                        <div className="caption"><div><font style={{ fontSize: '13px'}}>Total necessário em {ano}</font></div></div>
                                        
                                    </div>
                                </article>

                            </div>
                            <div className="col-sm-3">
                                <article className="statisticP-box red">
                                    <div>
                                        
                                        <div className="number" style={{ fontSize: '35px' }}>{totalPlanejadoEmProcesso}</div>
                                        
                                        <div className="caption"><div><font style={{ fontSize: '13px'}}>Planejado</font></div></div>
                                        
                                    </div>
                                </article>
                            </div>
                            
                            
                            <div className="col-sm-3">
                                <article className="statisticP-box green">
                                    <div>
                                        <div className="number" style={{ fontSize: '35px' }}>{totalPlanejadoEmpenhado}</div>
                                        <div className="caption"><div><font style={{ fontSize: '13px'}}>Planejado</font></div></div>
                                        
                                    </div>
                                </article>
                            </div>
                            
                            
                        </div>
                    </div>


                    <div className="col-xl-12" >
                        <div className="row">
                            <div className="col-sm-3">
                               
                            </div>
                            <div className="col-sm-3">




                                <article className="statisticP-box purple">
                                    <div>
                                        <div className="number" style={{ fontSize: '35px' }}>{totalNaoPlanejado}</div>
                                        
                                        <div className="caption"><div><font style={{ fontSize: '13px'}}>Não Planejado</font></div></div>
                                        
                                    </div>
                                </article>



                                
                            </div>
                            <div className="col-sm-3">
                                <article className="statisticP-box red">
                                    <div>
                                        <div className="number" style={{ fontSize: '35px' }}>{totalNaoPlanejadoEmProcesso}</div>
                                        
                                        <div className="caption"><div><font style={{ fontSize: '13px'}}>Não Planejado</font></div></div>
                                        
                                    </div>
                                </article>
                            </div>
                            
                            
                            <div className="col-sm-3">
                                <article className="statisticP-box green">
                                    <div>
                                        <div className="number" style={{ fontSize: '35px' }}>{totalNaoPlanejadoEmpenhado}</div>
                                        <div className="caption"><div><font style={{ fontSize: '13px'}}>Não Planejado</font></div></div>
                                        
                                    </div>
                                </article>
                            </div>
                            
                            
                        </div>
                    </div>

                        
                    <div className="col-sm-12">
                
                        <br/><br/>

                    </div>

                        
                    <div className="col-sm-3">
                
                                
                        
                        
                        <PieChart width={460} height={480}>
                            <Legend layout="vertical" verticalAlign="top" align="top" />
                            <Pie labelLine={false} data={data2} dataKey="total" outerRadius={150} fill="green" label={renderCustomizedLabel2}  
                            style={{ cursor: 'pointer'}}
                            onClick={(e) => detalhePieChart(e)}
                            >
                            {data2.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            </Pie>
                        </PieChart>
                    

                    </div>

                    <div className="col-sm-6">
                            
                        <table className="table table-striped table-bordered table-condensed" >
                            <thead>
                            <tr >
                                <th style={{ textAlign: 'center'}}>
                                    Avaliação
                                </th>
                                <th style={{ textAlign: 'center'}}>
                                    Quantidade Itens
                                </th>
                                
                            </tr>
                            </thead>
                            <tbody>
                                {resultadoAvaliacao}
                            </tbody>
                        </table>

                    </div>


                    <div className="col-md-12">
                        <fieldset className="form-group">
                            
                            <br/><br/><br/>
                            <select className="form-control" value={tipoAutonomia} onChange={event => setTipoAutonomia(event.target.value)} >
                                <option value='A'>Autonomia em pelo consumo em meses</option>
                                <option value='P'>Autonomia pela meta em meses</option>

                            </select>
                        </fieldset>
                    </div>

                    <div className="col-sm-12">

                    
                    <ResponsiveContainer width='100%' aspect={4.0/1.0}>
                                <BarChart
                                
                                data={data}
                                margin={{top: 5, right: 0, left: 0, bottom: 25}}
                                
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name"
                                    fontFamily="sans-serif"
                                    fontSize='1.0rem'
                                    tickSize
                                    dy='20'
                                    textAnchor="middle"
                                    label={{ position: 'top' }} />
                                <YAxis hide />
                                <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                                
                                
                                <Bar dataKey={'total'} position="middle"  fill="#8884d8"  style={{ cursor: 'pointer'}} onClick={(e) => detalheBarChart(e)}>
                                    <LabelList dataKey={'total'} content={renderCustomizedLabel} label={{ position: 'top' }} />
                                    
                                </Bar>
                                        
                                
                                </BarChart>
                        </ResponsiveContainer>



                    </div>

          
                    <div class="row">
                        
                        <div class="col-sm-12 col-md-12">
        
                                <table className="table table-striped table-bordered table-condensed" style={{ display: 'none' }}>
                                    <thead>
                                    <tr>
                                        <th>
                                            Usuário
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {usuariosOn}
                                    </tbody>
                                </table>

                        </div>
                    </div>                
                </div>
            </div>


            <ModalDetalhePieChart dadosGrafico={dadosGrafico} />
            <ModalDetalheBarChart dadosGrafico={dadosGrafico} tipoAutonomia={tipoAutonomia} />


        </div>

    )


}

export default Home