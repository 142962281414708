import { useNavigate } from "react-router-dom"
import { useState } from "react"
import api from "../components/api";
import { validaCPF } from '../functions/generalFunctions'
import InputMask from 'react-input-mask'


const LoginPage = () => {


    const navigate = useNavigate()



    const [cpf, setCPF] = useState('')
    const [senha, setSenha] = useState('')

    const login = () => {

        if (!validaCPF(cpf.replaceAll('.', '').replaceAll('-', ''))) {

            window.toastr.error('CPF inválido!')
            return false;

        }

        if (senha == '') {

            window.toastr.error('Por favor, informe a senha!')
            return false;

        }

        var dataPost = {

            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
            senha: senha,
            origem: 'planejamento',

        }
        
        //console.log(dataPost)
        
        api.post('login', dataPost).then((result) => {

            console.log(result.data)

            var data = result.data[0]

            
            //return false

            if (data.status == 'logado') {


                sessionStorage.setItem('logado', true)
                sessionStorage.setItem('usuario_id', data.usuario_id)
                sessionStorage.setItem('nome', data.nome.toUpperCase())
                sessionStorage.setItem('perfil', data.perfil)
                sessionStorage.setItem('somenteLeitura', data.somenteLeitura)
                window.location.href = 'Inicio'


            } else {

                window.toastr.error('Usuário ou senha inválida!')

            }

        }).catch((err) => {

            console.log(err)

        })

        }

        //window.location.href = 'Inicio'
        
        



    return (


            <div className="login_page" >

                
                    <body >

                            <br/><br/><br/><br/><br/><br/><br/><br/>
                            <br/><br/><br/><br/>
                            
                    
                            <div className="login_box" >
                                
                                <form id="login_form">
                                    <div class="top_b"><center>Planejamento Consumo</center></div>    
                                        <br/>
                                        <center>
                                        <img src='/logo_dante.jpeg' style={{ width: '200px'}} />
                                        </center>
                                    <div class="cnt_b">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon input-sm"><i class="glyphicon glyphicon-user"></i></span>
                                                <InputMask mask="999.999.999-99" class="form-control input-sm" type="text" value={cpf} onChange={event => setCPF(event.target.value)} placeholder="CPF" 
                                                
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        login()                                            
                                                    }
                                                }}
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="input-group">
                                                <span class="input-group-addon input-sm"><i class="glyphicon glyphicon-lock"></i></span>
                                                <input class="form-control input-sm" type="password" id="password" value={senha} onChange={event => setSenha(event.target.value)}  placeholder="Senha"
                                                
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        login()                                            
                                                    }
                                                }}
                                                
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="btm_b clearfix">
                                        <button class="btn btn-default btn-sm pull-right" type="button" onClick={() => login()}>Login</button>
                                        
                                    </div>  
                                </form>
                                
                                
                                
                                <div class="links_b links_btm clearfix" style={{ display: 'none'}}>
                                    <span class="linkform"><a href="#pass_form">Forgot password?</a></span>
                                    <span class="linkform" style={{ display: 'none' }}>Never mind, <a href="#login_form">send me back to the sign-in screen</a></span>
                                </div>
                                
                            </div>
                            
                            
                        </body>

            </div>



    )
}

export default LoginPage