import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'
import moment from 'moment'

import ModalEntrega from '../modal/ModalEntrega'


const Entrega = () => {


    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()
    const [ano, setAno] = useState(anoAtual.toString())

    const [empenho_id, setEmpenho_id] = useState()

    const [itemDescri, setItemDescri] = useState('')
    const [resultadoBusca, setResultadoBusca] = useState('')

    const [siafisicoPregao, setSiafisicoPregao] = useState('')
    const [descricao, setDescricao] = useState('')
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [item_id, setItem_id] = useState()


    const [totalQtde, setTotalQtde] = useState(0)
    const [totalQtdeEntregar, setQtdeEntregar] = useState(0)
    
    const [mediaValorUnit, setMediaValorUnit] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)

    const [qtdeEntregue, setQtdeEntregue] = useState(0)
    const [totalPercEntregue, setTotalPercEntregue] = useState(0)
    


    const [totalQtdeT, setTotalQtdeT] = useState(0)
    const [qtdeEntregueT, setQtdeEntregueT] = useState(0)
    const [qtdeEntregarT, setQtdeEntregarT] = useState(0)
    const [totalPercEntregueT, setTotalPercEntregueT] = useState(0)
    const [mediaValorUnitT, setMediaValorUnitT] = useState(0)
    const [valorTotalT, setValorTotalT] = useState(0)

    const [resultadoEmpenhos, setResultadoEmpenhos] = useState([])


    const [resultadoEntregas, setResultadoEntregas] = useState()


    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }


    const carregaEntregas = () => {

        if (item_id) {


            api.get(`entregaItem/${item_id}/${ano}`).then((result) => {

                //console.log(result.data)

                setResultadoEntregas(result.data.map((rs) => 
                
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            {rs.dataEntrega}
                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            {fmtMoney(rs.quantidade).toString().replaceAll('R$', '')}
                        </td>
                        <td style={{ fontSize: '11px'}}>
                            {rs.observacao}
                        </td>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            {rs.recebidoPor}
                        </td>
                    </tr>
                
                ))


            }).catch((err) => {


                console.log(err.response)


            })

        }

        
    }

    useEffect(() => {

        if (item_id) {

            carregaEntregas()

        }

    }, [item_id, ano])


    const carregaEmpenhos = () => {

        if (item_id && ano) {


            api.get(`empenhoItem/${item_id}/${ano}`).then((result) => {


                var dataTotais = result.data[1][0]

                //console.log(dataTotais)
                
                setTotalQtdeT(fmtMoney(dataTotais.totalQtde, 0).replaceAll('R$', ''))
                setMediaValorUnitT(fmtMoney(dataTotais.mediaValorUnit, 2))
                setValorTotalT(fmtMoney(dataTotais.valorTotal, 2))
                setQtdeEntregarT(fmtMoney(dataTotais.totalQtdeEntregar, 0).replaceAll('R$', ''))
                setQtdeEntregueT(fmtMoney(dataTotais.totalQtdeEntregue, 0).replaceAll('R$', ''))                
                setTotalPercEntregueT(dataTotais.percQtdeEntregue == null ? 0 : fmtMoney(dataTotais.percQtdeEntregue, 0).replaceAll('R$', ''))
    
    
                var data = result.data[0]
    
                setResultadoEmpenhos(data.map((rs) => 
                
                    <tr style={{ fontSize: '11px', cursor: 'pointer' }} onClick={() => {window.$('.modal-Entrega').modal('show');setEmpenho_id(rs.empenho_id)}}>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            {rs.dataEmissao}
                            
                        </td>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            
                            <span class="label label-info" style={{ fontSize: '11px'}}>{rs.empenho}</span>
                            
                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.quantidade == null ? '0' : fmtMoney(rs.quantidade, 0).replaceAll('R$', '')}
    
                        </td>
                       
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.totalQtdeEntregue == null ? '0' : fmtMoney(rs.totalQtdeEntregue, 0).replaceAll('R$', '')}
    
                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.totalQtdeEntregar == null ? '0' : fmtMoney(rs.totalQtdeEntregar, 0).replaceAll('R$', '')}
    
                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.percQtdeEntregue == null ? '0' : fmtMoney(rs.percQtdeEntregue, 0).replaceAll('R$', '')}%
    
                        </td>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            {rs.unidFornecimento}
                            
                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.valorUnit == null ? '0' : fmtMoney(rs.valorUnit, 2)}
    
                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.valorTotal == null ? '0' : fmtMoney(rs.valorTotal, 2)}
    
                        </td>
                       
                        
                    </tr>
                
                ))
    
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })


        }

        


    }



    useEffect(() => {

        if (itemDescri != '') {

            var dataPost = {

                itemDescri: itemDescri,

            }

            api.post('buscaItem', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoBusca(result.data.map((rs) =>
                
                    <Link onClick={() => {setItem_id(rs.item_id);setSiafisicoPregao(rs.siafisicoPregao);setDescricao(rs.descricao);setUnidFornecimento(rs.unidFornecimento)}}><font color='purple'>[{rs.siafisicoPregao}]</font> - {rs.descricao}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [itemDescri])



    useEffect(() => {

        carregaAnos()

    }, [])


    useEffect(() => {


        if (siafisicoPregao != '') {

            setResultadoBusca('')
            setItemDescri('')
            setEmpenho_id()

        } else {

            setResultadoBusca('')

        }


    }, [siafisicoPregao])




    useEffect(() => {
        
        carregaEmpenhos()
        
    }, [siafisicoPregao, ano])



    


    return (


        <div>

            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                                Entrega de Materiais
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
        

                            <div className="row">

                                <div className="col-md-12 col-sm-6">
                                    <fieldset className="form-group">

                                        <textarea type="text" placeholder='Busque pelo item...' className="form-control" value={itemDescri} onChange={event => setItemDescri(event.target.value)} />
                                        {resultadoBusca}

                                    </fieldset>
                                </div>



                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Cód. Siafisico</label>
                                        <input type="text" className="form-control" value={siafisicoPregao} style={{ backgroundColor: '#d7e5f7'}} 
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Descrição</label>
                                        <textarea type="text" className="form-control" value={descricao}  rows="2" disabled={true} />
                                    </fieldset>
                                </div>

                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Unidade Fornecimento</label>
                                        <input type="text" className="form-control" value={unidFornecimento} style={{ backgroundColor: '#e0d1e8'}} 
                                        />
                                    </fieldset>
                                </div>




                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label" >Ano</label>
                                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                            {listaAnos}
                                        </select>
                                        
                                    </fieldset>
                                </div>



                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>

                                        <fieldset className="form-group">
                                            <br/>

                                            <label className="form-label" >Clique sobre o empenho abaixo para registrar a entrega:</label>

                                            <table className="table table-striped table-bordered table-condensed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Data emissão</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Empenho</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Qtde empenho</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Qtde entregue</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Qtde entregar</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>% entregue</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Unid.</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Valor Unit.</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Valor Total</div></th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                
                                                    {resultadoEmpenhos}

                                                </tbody>


                                                <tfoot>
                                                    <tr style={{ fontSize: '11px', textAlign: 'right'}}>
                                                        <th colspan="2" style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'center'}}>
                                                            Total
                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                            {totalQtdeT}
                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                            {qtdeEntregueT}
                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                            {qtdeEntregarT}
                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                            {totalPercEntregueT}%
                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4'}} >
                                                            &nbsp;
                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                            {mediaValorUnitT}
                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                            {valorTotalT}
                                                        </th>
                                                        
                                                    </tr>
                                                </tfoot>

                                                
                                            </table>

                                        </fieldset>


                                </div>



                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>

                                        <fieldset className="form-group">

                                            <label className="form-label" >Entregas</label>

                                            <table className="table table-striped table-bordered table-condensed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Data entrega</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Quantidade</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Observação</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Por</div></th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                
                                                    {resultadoEntregas}

                                                </tbody>

                                               
                                                
                                            </table>

                                        </fieldset>


                                </div>
                                    
                                    


                            </div>


                        </div>
                    </div>                
                </div>
            </div>



            <ModalEntrega empenho_id={empenho_id} carregaEmpenhos={carregaEmpenhos} carregaEntregas={carregaEntregas} item_id={item_id} />


        </div>


    )

}

export default Entrega