import { useEffect, useState } from "react"
import { fmtMoney } from "../functions/generalFunctions"
import api from '../components/api'
import InputMask from 'react-input-mask'
import moment from 'moment'
import { textAlign } from "@mui/system"


const Modal = (props) => {


    const empenho_id = props.empenho_id
    const item_id = props.item_id


    const [dataEmissao, setDataEmissao] = useState('')
    const [empenho, setEmpenho] = useState('')
    const [quantidade, setQuantidade] = useState(0)
    const [valorUnit, setValorUnit] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [numeroProtocolo, setNumeroProtocolo] = useState('')
    const [modalidade, setModalidade] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [fornecedor, setFornecedor] = useState('')
    const [siafisicoPregao, setSiafisicoPregao] = useState('')    
    const [descricao, setDescricao] = useState('')
    

    const [quantidadeRecebida, setQuantidadeRecebida] = useState('')


    const [dataEntrega, setDataEntrega] = useState('')
    const [observacao, setObservacao] = useState('')

    const [resultadoEntregas, setResultadoEntregas] = useState()

    


    const salvar = () => {


        if (quantidadeRecebida == '') {

			window.toastr.error('<b>Quantidade Recebida</b> não pode ficar em branco!');
			return false;

		}

        if (moment(dataEntrega, "DD/MM/YYYY", true).isValid() == false) {

			window.toastr.error('<b>Data de entrega</b> inv&aacute;lida!');
			return false;

		}

        var dataEntrega_array = dataEntrega.split('/')
        var dataEntrega_msk = `${dataEntrega_array[2]}-${dataEntrega_array[1]}-${dataEntrega_array[0]}`


        var dataPost = {

            empenho_id: empenho_id,
            item_id: item_id,
            quantidade: quantidadeRecebida,
            data: dataEntrega_msk,
            observacao: observacao,
            recebidoPor: sessionStorage.getItem('nome'),

        }

        //console.log(dataPost)


                    
        api.post(`entrega`, dataPost).then((result) => {

            //console.log(result.data)
            
            //window.toastr.success('Salvo com sucesso!')
            carregaEntregas()
            
            setQuantidadeRecebida('')
            setObservacao('')
            setDataEntrega('')

            props.carregaEntregas()
            props.carregaEmpenhos()

        }).catch((err) => {

            console.log(err.response)

        })

        

        

        
    }



    const carrega = () => {

        if (empenho_id && item_id) {

            //console.log(`empenho/${empenho_id}`)

            api.get(`empenho/${empenho_id}/${item_id}`).then((result) => {

                const data = result.data[0]
                
                setEmpenho(data.empenho)
                setDataEmissao(data.dataEmissao)
                setQuantidade(data.quantidade)
                setValorUnit(fmtMoney(data.valorUnit, 2).replaceAll('R$', ''))
                setValorTotal(fmtMoney(data.valorTotal, 2).replaceAll('R$', ''))
                setUnidFornecimento(data.unidFornecimento)
                setNumeroProtocolo(data.numeroProtocolo)
                setModalidade(data.modalidade)
                setCnpj(data.cnpj)
                setFornecedor(data.fornecedor)
                setSiafisicoPregao(data.siafisicoPregao)                
                setDescricao(data.descricao)
                


            }).catch((err) => {

                console.log(err)

            })

        }

    }

    const apagar = (itemEntrega_id) => {


        api.delete(`entrega/${itemEntrega_id}`).then((result) => {

            console.log(result.data)
            //window.toastr.error('Apagado com sucesso!')
            carregaEntregas()
            
            props.carregaEntregas()
            props.carregaEmpenhos()

        }).catch((err) => {

            console.log(err.response)

        })


    }


    const carregaEntregas = () => {


        if (empenho_id) {


            api.get(`entregaEmpenho/${empenho_id}/${item_id}`).then((result) => {

                
                setResultadoEntregas(result.data[1].map((rs) => 

                    <tr>

                        <td style={{ textAlign: 'center'}}>
                            
                            {rs.dataEntrega}
                            
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {fmtMoney(rs.quantidade).toString().replaceAll('R$', '')}
                        </td>
                        <td>
                            {rs.observacao}
                        </td>
                        <td>
                            {rs.recebidoPor}
                        </td>
                        <td>
                            <center>
                                <button type="button" className="btn btn-inline btn-danger" onClick={() => apagar(rs.itemEntrega_id)} >x</button>
                            </center>
                        </td>

                    </tr>


                ))


            }).catch((err) => {

                console.log(err.response)

            })

        }


    }


    useEffect(() => {

        setObservacao('')
        setDataEntrega('')
        setQuantidadeRecebida('')

        if (empenho_id) {

            carrega()
            carregaEntregas()

        }

    }, [empenho_id])


    return (

        <div>


                <div class="modal fade modal-Entrega"
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Entrega de material</h4>
                        </div>
                        <div class="modal-body">

                        <div className="row">


                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">NE</label>
                                        <input type="text" className="form-control" value={empenho} style={{ backgroundColor: '#ebd1d1', textTransform: 'uppercase'}} maxLength={12} onChange={event => setEmpenho(event.target.value)} disabled={true} />
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Emissão</label>
                                        <InputMask mask="99/99/9999" type="text" className="form-control" value={dataEmissao} onChange={event => setDataEmissao(event.target.value)} disabled={true}  />
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Unid. Fornecimento</label>
                                        <input type="text" className="form-control" value={unidFornecimento} onChange={event => setUnidFornecimento(event.target.value)} style={{ textTransform: 'uppercase'}} disabled={true} />
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Quantidade</label>
                                        <input type="text" className="form-control" value={quantidade} onChange={event => setQuantidade(event.target.value)} 
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} disabled={true} 
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-12">
                                    <br/>
                                    <b>Fornecedor</b>
                                    
                                    <br/><br/>
                                </div>

                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">CNPJ</label>
                                        <InputMask mask="99.999.999/9999-99" type="text" className="form-control" value={cnpj} onChange={event => setCnpj(event.target.value)} style={{ backgroundColor: '#ebebd1' }}   disabled={true}                                     
                                        
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-8">
                                    <fieldset className="form-group">
                                        <label className="form-label">Razão Social</label>
                                        <input type="text" className="form-control" value={fornecedor} onChange={event => setFornecedor(event.target.value)}  style={{ backgroundColor: '#ebebd1', textTransform: 'uppercase' }} disabled={true}  />
                                    </fieldset>
                                </div>


                                <div className="col-md-12">
                                    <br/>
                                    <b>Entregas</b>
                                    
                                    <br/><br/>

                                    
                                </div>

                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>

                                        <fieldset className="form-group">

                                            

                                            <table className="table table-striped table-bordered table-condensed">
                                                <thead>
                                                    <tr>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Data entrega</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Quantidade</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Observação</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Por</div></th>
                                                        <th style={{ fontSize: '11px', textAlign: 'center'}}><div>&nbsp;</div></th>
                                                        
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                
                                                    {resultadoEntregas}

                                                    <tr style={{ alignItems: 'center'}}>
                                                        <td>
                                                            <InputMask mask="99/99/9999" type="text" className="form-control" value={dataEntrega} onChange={event => setDataEntrega(event.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input type="text" className="form-control" value={quantidadeRecebida} onChange={event => setQuantidadeRecebida(event.target.value)} 
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                                }
                                                            }} 
                                                            />
                                                        </td>
                                                        <td>
                                                            <textarea className="form-control" value={observacao} onChange={event => setObservacao(event.target.value)} rows="2" maxLength={500} />
                                                        </td>
                                                        <td>
                                                            {sessionStorage.getItem('nome')}
                                                        </td>
                                                        <td>
                                                            <center>
                                                                <button type="button" className="btn btn-inline btn-success" onClick={() => salvar()} >+</button>
                                                            </center>
                                                        </td>
                                                    </tr>

                                                </tbody>

                                            </table>

                                        </fieldset>


                                </div>

                               
                        </div>

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )


}

export default Modal