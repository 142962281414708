import { useEffect, useState } from "react"
import api from "../components/api"
import moment from 'moment'

const Modal = (props) => {

    var slug = props.item_id

    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()   
    const [ano, setAno] = useState(anoAtual.toString())

    const [siafisicoPregao, setSiafisicoPregao] = useState('')
    const [descricao, setDescricao] = useState('')
    const [item_id, setItem_id] = useState('')

    const [metaConsumoAtual, setMetaConsumoAtual] = useState('')
    const [metaCorrigida, setMetaCorrigida] = useState('')


    const [codigoMestreMV, setCodigoMestreMV] = useState('')
    const [codigoFilhoMV, setCodigoFilhoMV] = useState('')



    const carregaAnos = () => {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }






    const salva = () => {

    
        if (metaCorrigida == '') {

            window.toastr.error('<b>Nova meta consumo</b> não pode ficar em branco!')
            return false

        }


        if (item_id == '') {

            window.toastr.error('Por favor, informe um item!')
            return false

        }


        var dataPost = {

            ano: ano,
            metaCorrigida: metaCorrigida,
            metaConsumoAtual: metaConsumoAtual,
            item_id: item_id,
            usuario_id: sessionStorage.getItem('usuario_id'),
            nome: sessionStorage.getItem('nome'),

        }


        console.log(dataPost)


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'orange',
		    title: 'Confirmação',
		    content: 'Confirma altera a meta deste item?',
		    buttons: {
		        Sim: function () {




                                
                    api.post('planejamentoCorrigido', dataPost).then((result) => {

                        //console.log(result.data)
                        limpa()
                        props.carrega()
                        props.carregaAlteracoes()
                        window.toastr.success('Registro salvo com sucesso!')
                        window.$('.modal-AlteraMeta').modal('hide')
                        return false

                    }).catch((err) => {

                        console.log(err.response)

                    })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }



    const infoItem = () => {

        if (siafisicoPregao != '' || codigoMestreMV != '' || codigoFilhoMV != '') {


            var dataPost = {

                siafisicoPregao: siafisicoPregao,
                codigoMestreMV: codigoMestreMV,
                codigoFilhoMV: codigoFilhoMV,

            }

            api.post('infoItem', dataPost).then((result) => {

                if (result.data) {

                    setDescricao(result.data[0].descricao)
                    setSiafisicoPregao(result.data[0].siafisicoPregao)
                    setCodigoMestreMV(result.data[0].codigoMestreMV)
                    setCodigoFilhoMV(result.data[0].codigoFilhoMV)
                    setItem_id(result.data[0].item_id)

                } else {

                    window.toastr.error('<b>Sisfisico</b> não encontrado!')
                    setDescricao('')
                    setItem_id('')
                    setCodigoFilhoMV('')
                    setCodigoMestreMV('')

                }

                

            }).catch((err) => {

                console.log(err.response)
                window.toastr.error('<b>Sisfisico</b> não encontrado!')
                setDescricao('')
                setItem_id('')
                setCodigoFilhoMV('')
                setCodigoMestreMV('')

            })

        }
        
    }


    const carrega = () => {

        if (item_id && ano) {

            api.get(`planejamentoItem/${item_id}/${ano}`).then((result) => {

                console.log(result.data)
                
                var data = result.data[0]

                setMetaConsumoAtual(data.metaConsumo)


            }).catch((err) => {

                console.log(err.response)

            })
            
        }

    }


    const limpa = () => {

        setSiafisicoPregao('')
        setItem_id('')
        setDescricao('')
        setMetaConsumoAtual('')
        setMetaCorrigida('')


    }

    useEffect(() => {

        limpa()

    }, [slug])

    useEffect(() => {

        carregaAnos()


    }, [])


    useEffect(() => {

        
        carrega()        


    }, [ano, item_id])


    return (


        <div>

                <div className={`modal fade modal-AlteraMeta`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Alteração de meta</h4>
                        </div>
                        <div class="modal-body">

                            <div className="row">

                                 <div className="col-md-3">
                                        <fieldset className="form-group">
                                            <label className="form-label">Cód. Siafisico</label>
                                            <input type="text" className="form-control" value={siafisicoPregao} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setSiafisicoPregao(event.target.value);setCodigoMestreMV('');setCodigoFilhoMV('');}}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                
                                                }
                                            }} 
                                            onBlur={() => infoItem()}
                                            />
                                        </fieldset>
                                    </div>

                                    <div className="col-md-3">
                                        <fieldset className="form-group">
                                            <label className="form-label">Cód. Mestre MV</label>
                                            <input type="text" className="form-control" value={codigoMestreMV} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setCodigoMestreMV(event.target.value);setSiafisicoPregao('');setCodigoFilhoMV('');}}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} 
                                            onBlur={() => infoItem()}
                                            />
                                        </fieldset>
                                    </div>

                                    <div className="col-md-3">
                                        <fieldset className="form-group">
                                            <label className="form-label">Cód. Filho MV</label>
                                            <input type="text" className="form-control" value={codigoFilhoMV} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setCodigoFilhoMV(event.target.value);setSiafisicoPregao('');setCodigoMestreMV('');}}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} 
                                            onBlur={() => infoItem()}
                                            />
                                        </fieldset>
                                    </div>
                                

                                <div className="col-md-3 col-sm-6">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Ano</label>
                                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                            {listaAnos}
                                        </select>

                                    </fieldset>
                                </div>

                            
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Descrição</label>
                                        <textarea type="text" className="form-control" value={descricao}  rows="4" disabled={true} />
                                    </fieldset>
                                </div>

                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Meta consumo atual</label>
                                        <input type="text" className="form-control" value={metaConsumoAtual} disabled={true}
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Nova meta consumo</label>
                                        <input type="text" className="form-control" value={metaCorrigida} onChange={event => setMetaCorrigida(event.target.value)}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} 
                                        />
                                    </fieldset>
                                </div>

                            </div>

                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-success" onClick={() => salva()}  >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )

}

export default Modal