import { useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import CurrencyInput from '../components/CurrencyInput'
import { fmtMoney } from '../functions/generalFunctions'

import api from '../components/api'

const Modal = (props) => {

    var empenho_id = props.empenho_id
    var item_id = props.item_id

    const [dataEmissao, setDataEmissao] = useState('')
    const [empenho, setEmpenho] = useState('')
    const [quantidade, setQuantidade] = useState(0)
    const [valorUnit, setValorUnit] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [numeroProtocolo, setNumeroProtocolo] = useState('')
    const [modalidade, setModalidade] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [fornecedor, setFornecedor] = useState('')
    const [siafisicoPregao, setSiafisicoPregao] = useState('')    
    const [descricao, setDescricao] = useState('')
    //const [item_id, setItem_id] = useState('')

    const [percQtdeEntregue, setPercQtdeEntregue] = useState(0)
    const [totalQtdeEntregue, setTotalQtdeEntregue] = useState(0)
    const [saldoAEntregar, setSaldoAEntregar] = useState(0)
    const [valorAEntregar, setValorAEntregar] = useState(0)
    const [valorEntregue, setValorEntregue] = useState(0)


    const [resultadoEntregas, setResultadoEntregas] = useState([])

    const carrega = () => {

        if (empenho_id && item_id) {

            api.get(`entregaEmpenho/${empenho_id}/${item_id}`).then((result) => {

                

                const data = result.data[0][0]
                
                setEmpenho(data.empenho)
                setDataEmissao(data.dataEmissao)
                setQuantidade(data.quantidade)
                setValorUnit(fmtMoney(data.valorUnit, 2).replaceAll('R$', ''))
                setValorTotal(fmtMoney(data.valorTotal, 2).replaceAll('R$', ''))
                setUnidFornecimento(data.unidFornecimento)
                setNumeroProtocolo(data.numeroProtocolo)
                setModalidade(data.modalidade)
                setCnpj(data.cnpj)
                setFornecedor(data.fornecedor)
                setSiafisicoPregao(data.siafisicoPregao)                
                setDescricao(data.descricao)
                //setItem_id(data.item_id)
                setPercQtdeEntregue(data.percQtdeEntregue)
                setTotalQtdeEntregue(data.totalQtdeEntregue)
                setSaldoAEntregar(data.saldoAEntregar)
                setValorAEntregar(fmtMoney(data.valorAEntregar, 2).replaceAll('R$', ''))
                setValorEntregue(fmtMoney(data.valorEntregue, 2).replaceAll('R$', ''))


                setResultadoEntregas(result.data[1].map((rs) => 
                
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            {rs.dataEntrega}
                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            {fmtMoney(rs.quantidade).toString().replaceAll('R$', '')}
                        </td>                        
                    </tr>
                
                ))

            }).catch((err) => {
    
                console.log(err)
    
            })

        }     


    }


    useEffect(() => {

        carrega()

    }, [empenho_id, item_id])


    return (

        <div>

                <div className={`modal fade modal-SituacaoEntrega`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Situação Entrega {empenho_id} {item_id}</h4>
                        </div>
                        <div class="modal-body">


                        <div className="row">
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">NE {empenho}</label>
                                        <input type="text" className="form-control" value={empenho} style={{ backgroundColor: '#ebd1d1', textTransform: 'uppercase'}} disabled />
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Emissão</label>
                                        <InputMask mask="99/99/9999" type="text" className="form-control" value={dataEmissao} disabled />
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Unid. Fornecimento</label>
                                        <input type="text" className="form-control" value={unidFornecimento} disabled style={{ textTransform: 'uppercase'}} />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Quantidade</label>
                                        <input type="text" className="form-control" value={quantidade} disabled
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} 
                                        />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Valor Unitário</label>
                                        <CurrencyInput type="text" className="form-control" value={valorUnit} disabled placeholder="0,00" />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Valor Total</label>
                                        <CurrencyInput type="text" className="form-control" value={valorTotal} disabled placeholder="0,00" />
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Processo</label>
                                        <input type="text" className="form-control" value={numeroProtocolo} disabled />
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Modalidade</label>
                                        <input type="text" className="form-control" value={modalidade} disabled />
                                    </fieldset>
                                </div>


                                <div className="col-md-12">
                                    <br/>
                                    <b>Fornecedor</b>
                                    
                                    <br/><br/>
                                </div>

                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">CNPJ</label>
                                        <InputMask mask="99.999.999/9999-99" type="text" className="form-control" value={cnpj} onChange={event => setCnpj(event.target.value)} style={{ backgroundColor: '#ebebd1' }}       disabled                                 
                                        //onBlur={event => infoCNPJ()}
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-8">
                                    <fieldset className="form-group">
                                        <label className="form-label">Razão Social</label>
                                        <input type="text" className="form-control" value={fornecedor} onChange={event => setFornecedor(event.target.value)}  style={{ backgroundColor: '#ebebd1', textTransform: 'uppercase' }} disabled />
                                    </fieldset>
                                </div>


                                <div className="col-md-12">
                                    <br/>
                                    <b>Item</b>
                                    
                                    <br/><br/>
                                </div>



                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Cód. Siafisico</label>
                                        <input type="text" className="form-control" value={siafisicoPregao} style={{ backgroundColor: '#d7e5f7'}} onChange={event => setSiafisicoPregao(event.target.value)} disabled
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} 
                                        //onBlur={() => infoItem()} 
                                        />
                                    </fieldset>
                                </div>
                                
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Descrição</label>
                                        <textarea type="text" className="form-control" value={descricao}  rows="4" disabled={true} />
                                    </fieldset>
                                </div>




                                <div className="col-md-12">
                                    <br/>
                                    <b>Entrega</b>
                                    
                                    <br/><br/>
                                </div>

                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Total Qtde Entregue</label>
                                        <input type="text" className="form-control" value={totalQtdeEntregue} disabled  />
                                    </fieldset>
                                </div>

                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">% Qtde Entregue</label>
                                        <input type="text" className="form-control" value={percQtdeEntregue} disabled  />
                                    </fieldset>
                                </div>

                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Valor Entregue</label>
                                        <input type="text" className="form-control" value={valorEntregue} disabled  />
                                    </fieldset>
                                </div>

                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Valor a Entregar</label>
                                        <input type="text" className="form-control" value={valorAEntregar} disabled  />
                                    </fieldset>
                                </div>

                                <div className="col-md-12" >

                                    <fieldset className="form-group">




                                        <table className="table table-striped table-bordered table-condensed">
                                            <thead>
                                                <tr>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Data entrega</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Quantidade</div></th>
                                                    
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                            
                                                {resultadoEntregas}

                                            </tbody>

                                        
                                            
                                        </table>

                                       
                                    </fieldset>


                                    </div>

                            </div>


                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )


}

export default Modal