import { useState, useEffect } from "react"
import axios from 'axios'
import api from "../components/api"

const Modal = (props) => {

    const slug = props.download_id
    const linkAPI = process.env.REACT_APP_API
    const usuario = sessionStorage.getItem('nome')


    const [download_id, setDownload_id] = useState()
    const [file, setFile] = useState()
    const [categoria, setCategoria] = useState('')
    const [oculto, setOculto] = useState(false)
    const [descricao, setDescricao] = useState('')
    const [arquivo, setArquivo] = useState()

    const [faj, setFaj] = useState(false) 
    const [dante, setDante] = useState(true)

    useEffect(() => {

        limpa()
        setDownload_id(slug)
        carrega(slug)

    }, [slug])


    const limpa = () => {

        setDownload_id()
        setCategoria('')
        setOculto('')
        setDescricao('')
        setArquivo()
        setFile('')
        setDante(true)
        setFaj(false)


    }

    const carrega = (download_id) => {


        api.get(`download/${download_id}`).then((result) => {

            var data = result.data[0]

            //console.log(data)

            setDownload_id(data.download_id)
            setCategoria(data.categoria)
            setOculto(data.oculto)
            setDescricao(data.descricao)
            setArquivo(data.arquivoNome)
            setDante(data.dante)
            setFaj(data.faj)

        }).catch((err) => {

            console.log(err.response)

        })

    }


    const verArquivo = () => {

        if (arquivo) {

            window.open(`${process.env.REACT_APP_API}/upload/arquivos/${arquivo}`)

        }

    }


    const salva = () => {

        

        if ((file == null || file == '') && download_id == null) {


            window.toastr.error('Por favor, informe o <b>arquivo</b> para importação')
            return false


        }

        if (oculto != false && oculto != true && oculto != 'false' && oculto != 'true') {

            window.toastr.error('Por favor, informe se o arquivo é <b>oculto</b>')
            return false

        }


        if (dante != false && dante != true && dante != 'false' && dante != 'true') {

            window.toastr.error('Por favor, informe se o arquivo é para o <b>Dante Pazanesse</b>')
            return false

        }

        if (faj != false && faj != true && faj != 'false' && faj != 'true') {

            window.toastr.error('Por favor, informe se o arquivo é para a <b>Fundação Adib Jatene</b>')
            return false

        }

        if (descricao == '') {

            window.toastr.error('Por favor, informe a <b>descrição</b>')
            return false

        }

        if (categoria == '') {

            window.toastr.error('Por favor, informe a <b>categoria</b>')
            return false

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma importar o arquivo?',
		    buttons: {
		        Sim: function () {

                    

                    const formData = new FormData();
                    const imagefile = document.querySelector("#fileDownloadAdm");

                    

                    // 'fileimage' é o campo que o 'multer' procura o arquivo de imagem.
                    formData.append("arquivo", imagefile.files[0]);
                    formData.append("descricao", descricao);
                    formData.append("oculto", oculto);            
                    formData.append("categoria", categoria)
                    formData.append("download_id", download_id)
                    formData.append("usuario", usuario)
                    formData.append("dante", dante)
                    formData.append("faj", faj)
                    
                    //console.log('altera')
                    //return false

                    if (download_id) {

                        axios.put(`${linkAPI}/download`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'x-access-token': '!nf0$@ud#2',
                            }
                            }).then(response => {
        
                                console.log(response.data)
                                //window.$(".MovimentoImporta").modal('hide');
                                window.$('#modalDownloadAdm').modal('hide')
                                
                                props.consulta()
                                props.carregaCategorias()
                                window.toastr.success('Arquivo salvo com sucesso!')
                                setArquivo(response.data.file)
                                setFile('')
        
        
        
                            }).catch((err) => {
        
                                console.log(err)
                                console.log(err.response)
        
                            })
    


                    } else {

                        axios.post(`${linkAPI}/download`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'x-access-token': '!nf0$@ud#2',
                        }
                        }).then(response => {
    
                            console.log(response.data)
                            //window.$(".MovimentoImporta").modal('hide');
                            window.$('#modalDownloadAdm').modal('hide')
                            setArquivo(response.data.file)
                            setFile('')
                            props.consulta()
                            props.carregaCategorias()
                            window.toastr.success('Arquivo salvo com sucesso!')
    
    
    
                        }).catch((err) => {
    
                            console.log(err)
                            console.log(err.response)
    
                        })

                    }

                    


                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }

    const apaga = () => {


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar o arquivo?',
		    buttons: {
		        Sim: function () {

                    
                    api.delete(`download/${download_id}`).then((result) => {

                        console.log(result.data)
                        window.$('#modalDownloadAdm').modal('hide')
                                
                        props.consulta()
                        props.carregaCategorias()
                        window.toastr.success('Arquivo apagado com sucesso!')
                        limpa()

                    }).catch((err) => {

                        console.log(err.response)

                    })

                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    return (

        <div>

                <div className={`modal fade modal-DownloadAdm`}
                    id="modalDownloadAdm"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Download {download_id}</h4>
                        </div>
                        <div class="modal-body">

                            <div className="row">

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Descrição</label>
                                        <input type="text" className="form-control" maxLength={255} value={descricao} onChange={event => setDescricao(event.target.value)} />
                                                        
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Categoria</label>
                                        <input type="text" className="form-control" maxLength={255} value={categoria} onChange={event => setCategoria(event.target.value)} />
                                                        
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Oculto</label>
                                        <select className="form-control" value={oculto} onChange={event => setOculto(event.target.value)} >

                                            <option value="">[Selecione]</option>
                                            <option value={false}>Não</option>
                                            <option value={true}>Sim</option>

                                        </select>
                                                        
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Arquivo Fundação Adib Jatene</label>
                                        <select className="form-control" value={faj} onChange={event => setFaj(event.target.value)} >

                                            <option value="">[Selecione]</option>
                                            <option value={false}>Não</option>
                                            <option value={true}>Sim</option>

                                        </select>
                                                    

                                                        
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Arquivo Dante Pazzanese</label>
                                        <select className="form-control" value={dante} onChange={event => setDante(event.target.value)} >

                                            <option value="">[Selecione]</option>
                                            <option value={false}>Não</option>
                                            <option value={true}>Sim</option>

                                        </select>
                                                    

                                                        
                                    </div>
                                </div>
                           
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Arquivo</label>
                                        <input type="file" className="form-control" id="fileDownloadAdm" value={file} onChange={event => setFile(event.target.value)} />
                                                        
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-info" onClick={() => verArquivo()} style={{ display: download_id ? 'table-row' : 'none' }} >Ver arquivo atual</button>
                            <button type="button" className="btn btn-inline btn-danger" onClick={() => apaga()} style={{ display: download_id ? 'table-row' : 'none' }} >Apagar</button>
                            <button type="button" className="btn btn-inline btn-success" onClick={() => salva()}  >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )

}


export default Modal