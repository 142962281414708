import { useEffect, useState } from 'react'
import ItemDetalhe from '../componentsPages/itemDetalhe'
import api from '../components/api'

const Modal = (props) => {

    const item_id = props.item_id
    const [totalPlanej, setTotalPlanej] = useState(0)
    const [totalConsumo, setTotalConsumo] = useState(0)

    
    
    const carregaTotalPlanej = () => {

        if (item_id) {

            api.get(`planejamentoItemTotal/${item_id}`).then((result) => {

                var data = result.data[0]
                setTotalPlanej(data.total)

            }).catch((err) => {

                console.log(err.response)

            })

        }

    }


    const carregaTotalConsumo = () => {

        if (item_id) {

            api.get(`empenhoItemTotal/${item_id}`).then((result) => {

                var data = result.data[0]
                setTotalConsumo(data.total)

            }).catch((err) => {

                console.log(err.response)

            })

        }

    }



    useEffect(() => {

        window.$('[href="#tabs-4-tab-1k"]').tab('show');
        //carregaTotalPlanej()
        carregaTotalConsumo()

    }, [item_id])

    return (

        <div>

            <div class='modal fade modal-CatalogoEdit'
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                    <div class="modal-dialog modal-lg" >
                        <div class="modal-content"  >
                            <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                                <h4 class="modal-title" id="myModalLabel">Catálogo - Item {item_id}</h4>
                            </div>
                            <div class="modal-body">


                            



                                <ItemDetalhe item_id={item_id} carregaGrupo={props.carregaGrupo} carregaItens={props.carregaItens} />



                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary" data-dismiss="modal">Fechar</button>
                            
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    )

}

export default Modal