import { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMes, fmtMoney, sleep } from '../functions/generalFunctions';
import LoaderIcon from "react-loader-icon";


const ItensCriticos = () => {

    const navigate = useNavigate()

    const [resultado, setResultado] = useState([])
    

    const anoAtual = moment().year()
    const [ano, setAno] = useState(anoAtual.toString())
    const [listaAnos, setListaAnos] = useState()   

    const [carregando, setCarregando] = useState('none')
    

    const carregaAnos = () => {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }

    const carrega = () => {

        var dataPost = {

          ano: ano,
          ordem: 'Alfabetica',

        }

        api.post('itensCriticosCP', dataPost).then((result) => {

            //console.log(result.data, '!!')

            setResultado(result.data)

        }).catch((err) => {

            console.log(err.response)

        })

    }


    useEffect(() => {

        carregaAnos()
        carrega()

    }, [ano])


    
    const columns = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao',
            header: 'Siafisico Pregão',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

          {
            accessorKey: 'codigoMestreMV', //access nested data with dot notation
            header: 'Cód. Mestre MV',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

          {
            accessorKey: 'codigoFilhoMV', //access nested data with dot notation
            header: 'Cód. Filho MV',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

         
          {
            accessorKey: 'descricao',
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'unidFornecimento',
            header: 'Unidade',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'unidTempoConsumo',
            header: 'Unid. Tempo Consumo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          
          {
            accessorKey: 'totalProcessos', //normal accessorKey
            header: 'Processos',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'totalEmpenhos', //normal accessorKey
            header: 'Empenhos',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },


          {
            accessorKey: 'estoqueAtual', //normal accessorKey
            header: 'Estoque',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },


          {
            accessorKey: 'consumoMedio', //normal accessorKey
            header: 'CMM',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },

          {
            accessorKey: 'consumoMedioDiario', //normal accessorKey
            header: 'CMD',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },

          {
            accessorKey: 'autonomiaDiaria', //normal accessorKey
            header: 'Autonomia Diária',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },

          {
            accessorKey: 'autonomia', //normal accessorKey
            header: 'Autonomia Mensal',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },


          {
            accessorKey: 'autonomiaPlanejada', //normal accessorKey
            header: 'Autonomia Planejada',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },

         /*

          , Estoque e Autonomia
         
          {
            accessorKey: 'planejado',
            header: 'Planejado',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          */
        ],
        [],
      );



    const atualizaPlanejamentoExecucao = () => {


      window.$.confirm({
        //icon: 'fa fa-question',
          theme: 'dark',
          closeIcon: true,
          animation: 'scale',
          type: 'green',
          title: 'Confirmação',
          content: 'Confirma atualizar? Esta ação pode demorar um pouco',
          buttons: {
              Sim: function () {
  
                    //window.toastr.info('Aguarde...')
                    setCarregando('block')
  
                    api.get(`populaPlanejamentoExecucao/${anoAtual}`).then(() => {

                        window.toastr.success('Atualização concluída!')
                        setCarregando('none')
                        carrega()

                    }).catch((err) => {

                      console.log(err.response)
                      setCarregando('none')

                    })
  
                                      
  
                  },
              Cancelar: function () {
                  //$.alert('Canceled!');
              }
  
              },
  
          });
  


    }


    const exportaExcel = async () => {

          //console.log(dataPost)

        await api.get(`itensCriticosCPExcel/${ano}`).then((result) => {
    
          //console.log(result.data)

          if (result.data.alerta == 'ok') {

            window.toastr.info('Aguarde, gerando XLSX...')

              sleep(3000).then(() => {

                  //console.log(result.data[0].fileNameCompleto, '!!')
                  //window.location.href = result.data.arquivo
                  //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                  //window.location.href = `https://apiportaltransp.fajsaude.com.br/${result.data.arquivo}`
                  window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                  
                  //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
              })
              

          }

      }).catch((err) => {

          console.log(err.response)

      })

          

    }

    return (

        <div>


            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                                Itens Críticos
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
        
                                

                                <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()}  >Exportar Excel</button>&nbsp;
                                <button type="button" class="btn btn-inline btn-info" onClick={() => atualizaPlanejamentoExecucao()}  >Atualizar</button>
                                
                                        
                                <br/><br/>

                                <div className="row">
                                    <div className="col-md-12 col-sm-6">
                                        <fieldset className="form-group">
                                            <label className="form-label" for="exampleError">Ano</label>
                                            <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                                {listaAnos}
                                            </select>

                                        </fieldset>
                                    </div>
                                    

                                </div>

                                <LoaderIcon  type={"cylon"} style={{ display: carregando }} />
                        
                                <div style={{ display: carregando == 'none' ? 'block' : 'none'}}>

                                  <MaterialReactTable 
                                      
                                      columns={columns} 
                                      data={resultado} 
                                      localization={MRT_Localization_PT_BR}
                                      //rowsPerPage={20}
                                      //options={options}
                                      initialState={{ density: 'compact' }}
                                      muiTableHeadCellProps={{
                                          //easier way to create media queries, no useMediaQuery hook needed.
                                          sx: {
                                          fontSize: {
                                              xs: '8px',
                                              sm: '9px',
                                              md: '10px',
                                              lg: '11px',
                                              xl: '12px',
                                          },
                                          },
                                      }}
                                      muiTableBodyCellProps={{
                                          sx: {
                                              fontSize: {
                                              xs: '8px',
                                              sm: '9px',
                                              md: '10px',
                                              lg: '11px',
                                              xl: '12px',
                                              },
                                          },
                                      }}

                                      muiTableBodyRowProps={({ row }) => ({
                                      onClick: (event) => {
                                          //console.info(event, row.original.usuario_id);
                                          navigate(`/Item/${row.original.item_id}`)
                                          //window.$('.modal-CatalogoEdit').modal('show');setItem_id(row.original.item_id)

                                      },
                                      sx: {
                                          cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                      },
                                      })}
                                      
                                  />
                                </div>




                        </div>
                    </div>                
                </div>
            </div>


        </div>

    )


}


export default ItensCriticos