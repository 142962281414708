import axios from 'axios'



const api = axios.create({
    //baseURL: 'https://api-faj.koderia.com.br/',
    
    //baseURL: 'http://192.168.255.165:3378',
    baseURL: process.env.REACT_APP_API,
    //baseURL: 'https://apiportaltransp.fajsaude.com.br/',
    
    headers: {
        'Content-Type': 'application/json',
        //'x-access-token': Buffer.from(process.env.REACT_APP_API_KEY).toString('base64')
        'x-access-token': '!nf0$@ud#2',
    }
    
})

export default api  
