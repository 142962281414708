import { useState, useEffect, useMemo } from "react"
import moment from 'moment'
import api from "../components/api"
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMoney, sleep } from "../functions/generalFunctions";

import ModalSituacaoEntrega from '../modal/ModalSituacaoEntrega'



const Indicadores = () => {


    const [indicador, setIndicador] = useState('')
    const [mesesAutonomia, setMesesAutonomia] = useState('')
    
    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()
    const [ano, setAno] = useState(anoAtual.toString())

    const [resultadoSituacaoEntrega, setResultadoSituacaoEntrega] = useState([])

    const [resultado, setResultado] = useState([])
    const [mostraResultado, setMostraResultado] = useState(false)

    const [empenho_id, setEmpenho_id] = useState()
    const [item_id, setItem_id] = useState()

    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }



    const columns = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao', //access nested data with dot notation
            header: 'Siafisico',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'metaConsumo',
            header: 'Consumo Planejado',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          {
            accessorKey: 'estoque',
            header: 'Estoque atual',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          
          {
            accessorKey: 'consumoMedio',
            header: 'Consumo Médio mensal',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          {
            accessorKey: 'autonomia',
            header: 'Autonomia (meses)',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          
          
        ],
        [],
      );


      const columnsSituacaoEntrega = useMemo(
        () => [

            {
            accessorKey: 'empenho', //access nested data with dot notation
            header: 'NE',
            muiTableHeadCellProps: {
                align: 'center',
                },
            muiTableBodyCellProps: {
                align: 'center',
                },
            },
          {
            accessorKey: 'siafisicoPregao', //access nested data with dot notation
            header: 'Siafisico',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          /*
          {
            accessorKey: 'cnpj',
            header: 'CNPJ',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'fornecedor',
            header: 'Fornecedor',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          
          {
            accessorKey: 'unidFornecimento',
            header: 'Unid. Forn.',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'valorUnit',
            header: 'Valor Unit.',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
            
          {
            accessorKey: 'valorTotal',
            header: 'Valor Total',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          */
          {
            accessorKey: 'quantidade',
            header: 'Qtde.',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          
        

          {
            accessorKey: 'totalQtdeEntregue',
            header: 'Qtde. Entregue',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },

          {
            accessorKey: 'percQtdeEntregue',
            header: '% Qtde. Entregue',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          
          
          
        ],
        [],
      );


    const consulta = () => {

        if (indicador == '') {

            window.toastr.error('Por favor, selecione um indicador')
            return false

        }

        

        switch (indicador) {


            case 'autonomia':


                var dataPost = {

                    ano: ano,
                    mesesAutonomia: mesesAutonomia,

                }

                

                api.post(`itemAutonomia`, dataPost).then((result) => {

                    //console.log(result.data, '!!')

                    setMostraResultado(true)

                    setResultado(result.data.map((rs) => {

                        return {

                            siafisicoPregao: rs.siafisicoPregao,
                            descricao: rs.descricao,
                            autonomia: rs.autonomia,
                            consumoTotal: fmtMoney(rs.consumoTotal, 0).replaceAll('R$', ''),
                            consumoMedio: fmtMoney(rs.consumoMedio, 0).replaceAll('R$', ''),
                            estoque: fmtMoney(rs.estoque, 0).replaceAll('R$', ''),
                            metaConsumo: fmtMoney(rs.metaConsumo, 0).replaceAll('R$', ''),

                        }


                    }))


                }).catch((err) => {


                    console.log(err)

                })


            break


            case 'situacaoEntrega':

                

                api.get('entregaSituacao').then((result) => {


                    setMostraResultado(true)

                    setResultadoSituacaoEntrega(result.data.map((rs) => {


                        return {

                            cnpj: rs.cnpj,
                            fornecedor: rs.fornecedor,
                            dataEmissao: rs.dataEmissao,
                            empenho: rs.empenho,
                            item_id: rs.item_id,
                            siafisicoPregao: rs.siafisicoPregao,
                            descricao: rs.descricao,
                            unidFornecimento: rs.unidFornecimento,
                            quantidade: rs.quantidade,
                            valorUnit: rs.valorUnit,
                            valorTotal: rs.valorTotal,
                            totalQtdeEntregue: rs.totalQtdeEntregue,
                            percQtdeEntregue: rs.percQtdeEntregue,
                            valorEntregue: rs.valorEntregue,
                            saldoAEntregar: rs.saldoAEntregar,
                            valorAEntregar: rs.valorAEntregar,
                            empenho_id: rs.empenho_id,

                        }


                    }))

                }).catch((err) => {

                    console.log(err.response)

                })

            break


        }


    }





    


    
    const exportaExcel = () => {

        


        switch (indicador) {


            case 'autonomia':


                var dataPost = {

                    ano: ano,
                    mesesAutonomia: mesesAutonomia,

                }

                

                api.post('itemAutonomiaExcel', dataPost).then((result) => {

                    console.log(result.data)
            
                    if (result.data.status == 'ok') {
            
                    window.toastr.info('Aguarde, gerando XLSX...')
            
                        sleep(2000).then(() => {
            
                            //console.log(result.data[0].fileNameCompleto, '!!')
                            //window.location.href = result.data.arquivo
                            window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                            //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
            
                        })
                        
            
                    }
            
                }).catch((err) => {
            
                    console.log(err.response)
            
                })

            break

            case 'situacaoEntrega':


                    api.get('entregaSituacaoXLS').then((result) => {

                    //console.log(result.data)
            
                    if (result.data.status == 'ok') {
            
                    window.toastr.info('Aguarde, gerando XLSX...')
            
                        sleep(5000).then(() => {
            
                            //console.log(result.data[0].fileNameCompleto, '!!')
                            //window.location.href = result.data.arquivo
                            //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                            window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
            
                        })
                        
            
                    }
            
                }).catch((err) => {
            
                    console.log(err.response)
            
                })


            break

        }
            

    
    }


    useEffect(() => {

        carregaAnos()

    }, [])




    return (

        <div>

            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                            Indicadores
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div class="col-sm-12 col-md-12">



                            <div className="row">

                            <div className="col-md-3" >
                                    <fieldset className="form-group">
                                        
                                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                            {listaAnos}
                                        </select>
                                        
                                    </fieldset>
                                </div>

                                <div className="col-md-9 col-sm-6">
                                    <fieldset className="form-group">

                                        <select className="form-control" value={indicador} onChange={event => setIndicador(event.target.value)}>

                                            <option value="">[Selecione]</option>
                                            <option value="autonomia">Autonomia dos itens</option>
                                            <option value="situacaoEntrega">Situação entrega materiais</option>

                                        </select>

                                    </fieldset>
                                </div>

                                <div className="col-md-3 col-sm-6" style={{ display: indicador == 'autonomia' ? 'table-row' : 'none'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Meses de autonomia</label>
                                        <input type="text" className="form-control"  value={mesesAutonomia} onChange={event => setMesesAutonomia(event.target.value)} 
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} 
                                        />
                                            
                                    </fieldset>
                                </div>

                            </div>

                            <button type="button" class="btn btn-inline btn-primary" onClick={() => consulta()} style={{ display: indicador != '' ? 'table-row' : 'none'}}>Consultar</button>&nbsp;
                            <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} style={{ display: mostraResultado == true ? 'table-row' : 'none' }}>Exportar Excel</button>

                            <br/><br/>

                            
                            
                                <div className="col-sm-12 col-md-12" style={{ display: indicador == 'autonomia' && mostraResultado == true ? 'table-row' : 'none'}} >

                                            <MaterialReactTable 
                                                
                                                columns={columns} 
                                                data={resultado} 
                                                localization={MRT_Localization_PT_BR}
                                                //rowsPerPage={20}
                                                //options={options}
                                                initialState={{ density: 'compact' }}
                                                muiTableHeadCellProps={{
                                                    //easier way to create media queries, no useMediaQuery hook needed.
                                                    sx: {
                                                    fontSize: {
                                                        xs: '8px',
                                                        sm: '9px',
                                                        md: '10px',
                                                        lg: '11px',
                                                        xl: '12px',
                                                    },
                                                    },
                                                }}
                                                muiTableBodyCellProps={{
                                                    sx: {
                                                        fontSize: {
                                                        xs: '8px',
                                                        sm: '9px',
                                                        md: '10px',
                                                        lg: '11px',
                                                        xl: '12px',
                                                        },
                                                    },
                                                }}

                                                muiTableBodyRowProps={({ row }) => ({
                                                onClick: (event) => {
                                                    //console.info(event, row.original.usuario_id);
                                                    //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                                    //window.$('.modal-Processo').modal('show');setProcesso_id(row.original.processo_id)

                                                },
                                                sx: {
                                                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                                },
                                                })}
                                                
                                            />
                                
                        </div>
                        

                            <div className="col-sm-12 col-md-12" style={{ display: indicador == 'situacaoEntrega'  && mostraResultado == true  ? 'table-row' : 'none'}} >

                                        <MaterialReactTable 
                                            
                                            columns={columnsSituacaoEntrega} 
                                            data={resultadoSituacaoEntrega} 
                                            localization={MRT_Localization_PT_BR}
                                            //rowsPerPage={20}
                                            //options={options}
                                            initialState={{ density: 'compact' }}
                                            muiTableHeadCellProps={{
                                                //easier way to create media queries, no useMediaQuery hook needed.
                                                sx: {
                                                fontSize: {
                                                    xs: '8px',
                                                    sm: '9px',
                                                    md: '10px',
                                                    lg: '11px',
                                                    xl: '12px',
                                                },
                                                },
                                            }}
                                            muiTableBodyCellProps={{
                                                sx: {
                                                    fontSize: {
                                                    xs: '8px',
                                                    sm: '9px',
                                                    md: '10px',
                                                    lg: '11px',
                                                    xl: '12px',
                                                    },
                                                },
                                            }}

                                            options = {{
                                                doubleHorizontalScroll: true
                                                }}

                                            style={{maxWidth: '100%', width: '100%', overflowx: 'auto'}}

                                            muiTableBodyRowProps={({ row }) => ({
                                            onClick: (event) => {
                                                //console.info(event, row.original.usuario_id);
                                                //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                                window.$('.modal-SituacaoEntrega').modal('show');setEmpenho_id(row.original.empenho_id);setItem_id(row.original.item_id)

                                            },
                                            sx: {
                                                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                            },
                                            })}
                                            
                                        />
                        
                        </div>
                    </div>
                
                        

                        </div>

                        


                    
                </div>
                                        
                
            </div>

            <ModalSituacaoEntrega empenho_id={empenho_id} item_id={item_id} />

        </div>

    )


}


export default Indicadores