import { useEffect, useState } from 'react'
import api from '../components/api'
import InputMask from 'react-input-mask'
import CurrencyInput from '../components/CurrencyInput'
import { fmtMoney, validaCNPJ } from '../functions/generalFunctions'
import moment from 'moment'

const Modal = (props) => {

    const empenho_id = props.empenho_id
    //const item_idP = props.item_id

    const perfil = sessionStorage.getItem('perfil')
    
    const [dataEmissao, setDataEmissao] = useState('')
    const [empenho, setEmpenho] = useState('')
    const [quantidade, setQuantidade] = useState(0)
    const [valorUnit, setValorUnit] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [numeroProtocolo, setNumeroProtocolo] = useState('')
    const [modalidade, setModalidade] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [fornecedor, setFornecedor] = useState('')
    const [siafisicoPregao, setSiafisicoPregao] = useState('')    
    const [descricao, setDescricao] = useState('')
    const [item_id, setItem_id] = useState('')

    const [codigoMestreMV, setCodigoMestreMV] = useState('')
    const [codigoFilhoMV, setCodigoFilhoMV] = useState('')



    const carrega = () => {

        if (empenho_id && item_id) {

            //console.log(`empenho/${empenho_id}/${item_id}`)

            api.get(`empenho/${empenho_id}/${item_id}`).then((result) => {

                console.log(result.data)

                const data = result.data[0]
                
                setEmpenho(data.empenho)
                setDataEmissao(data.dataEmissao)
                setQuantidade(data.quantidade)
                setValorUnit(fmtMoney(data.valorUnit, 2).replaceAll('R$', ''))
                setValorTotal(fmtMoney(data.valorTotal, 2).replaceAll('R$', ''))
                setUnidFornecimento(data.unidFornecimento)
                setNumeroProtocolo(data.numeroProtocolo)
                setModalidade(data.modalidade)
                setCnpj(data.cnpj)
                setFornecedor(data.fornecedor)
                setSiafisicoPregao(data.siafisicoPregao)                
                setDescricao(data.descricao)
                //setItem_id(data.item_id)
                setCodigoMestreMV(data.codigoMestreMV)
                setCodigoFilhoMV(data.codigoFilhoMV)


            }).catch((err) => {

                console.log(err)

            })

        }

    }



    const handleChange = (e) => {
        e.preventDefault();
    }

    


    const salvaEmpenho = () => {


        if (empenho == '') {

            window.toastr.error('<b>NE</b> não pode ficar em branco!')
            return false

        }

        if (moment(dataEmissao, "DD/MM/YYYY", true).isValid() == false) {

			window.toastr.error('<b>Data de emissão</b> inv&aacute;lida!');
			return false;

		}

        if (quantidade == '') {

			window.toastr.error('<b>Quantidade</b> não pode ficar em branco!');
			return false;

		}

        if (valorUnit == '') {

			window.toastr.error('<b>Valor Unitário</b> não pode ficar em branco!');
			return false;

		}


        if (valorTotal == '') {

			window.toastr.error('<b>Valor Total</b> não pode ficar em branco!');
			return false;

		}


        if (numeroProtocolo == '') {

			window.toastr.error('<b>Protocolo</b> não pode ficar em branco!');
			return false;

		}

        if (modalidade == '') {

			window.toastr.error('<b>Modalidade</b> não pode ficar em branco!');
			return false;

		}

        if (!validaCNPJ(cnpj.replaceAll('.', '').replaceAll('-','').replaceAll('/',''))) {


            window.toastr.error('<b>CNPJ</b> inválido!');
			return false;

        }

        if (fornecedor == '') {

			window.toastr.error('<b>Razão Social</b> não pode ficar em branco!');
			return false;

		}

        if (item_id == '') {

            window.toastr.error('<b>Cód. Siafisico</b> inválido!');
			return false;

        }


        var dataEmissao_array = dataEmissao.split('/')
        var dataEmissaoT = `${dataEmissao_array[2]}-${dataEmissao_array[1]}-${dataEmissao_array[0]}`


        var dataPost = {

            item_id: item_id,
            cnpj: cnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
            fornecedor: fornecedor.toUpperCase(),
            modalidade: modalidade.toUpperCase(),
            numeroProtocolo: numeroProtocolo.toUpperCase(),
            valorTotal: valorTotal.replaceAll('.', '').replaceAll(',','.'),
            valorUnit: valorUnit.replaceAll('.', '').replaceAll(',','.'),
            quantidade: quantidade,
            dataEmissao: dataEmissaoT,
            empenho: empenho.toUpperCase(),

        }

        //console.log(dataPost)

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar o empenho?',
		    buttons: {
		        Sim: function () {


                    if (empenho_id) {

                        api.put(`empenho/${empenho_id}`, dataPost).then((result) => {
            
                            //console.log(result.data)
                            if (result.data.alerta == 'ok') {

                                window.toastr.success('Registro salvo com sucesso!')
                                props.carregaEmpenhos()

                            }
            
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                    } else {
            
                        api.post('empenho', dataPost).then((result) => {
            

                            window.toastr.success('Registro salvo com sucesso!')
                            window.$('.modal-EmpenhoEdit').modal('hide')
                            props.carregaEmpenhos()
            
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                    }

                    

                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }


    const infoCNPJ = () => {

        if (!validaCNPJ(cnpj.replaceAll('.', '').replaceAll('-','').replaceAll('/',''))) {
        
            window.toastr.error('CNPJ inválido!')

        } else {

            api.get(`infoCNPJ/${cnpj.replaceAll('.', '').replaceAll('-','').replaceAll('/','')}`).then((result) => {

                //console.log(result.data)
                setFornecedor(result.data[0].fornecedor)

            }).catch((err) => {

                console.log(err.response)

            })

        }

    }

    const apagaEmpenho = () => {

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar o empenho?',
		    buttons: {
		        Sim: function () {

            
                        api.delete(`empenho/${empenho_id}`).then((result) => {
            
                            if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro apagado com sucesso!')
                                window.$('.modal-EmpenhoEdit').modal('hide')
                                props.carregaEmpenhos()

                            }

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                   
                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }

    const infoItem = () => {

        if (siafisicoPregao != '' || codigoMestreMV != '' || codigoFilhoMV != '') {


            var dataPost = {

                siafisicoPregao: siafisicoPregao,
                codigoMestreMV: codigoMestreMV,
                codigoFilhoMV: codigoFilhoMV,

            }

            api.post('infoItem', dataPost).then((result) => {

                if (result.data) {

                    setDescricao(result.data[0].descricao)
                    setSiafisicoPregao(result.data[0].siafisicoPregao)
                    setCodigoMestreMV(result.data[0].codigoMestreMV)
                    setCodigoFilhoMV(result.data[0].codigoFilhoMV)
                    setItem_id(result.data[0].item_id)

                } else {

                    window.toastr.error('<b>Sisfisico</b> não encontrado!')
                    setDescricao('')
                    setItem_id('')
                    setCodigoFilhoMV('')
                    setCodigoMestreMV('')

                }

                

            }).catch((err) => {

                console.log(err.response)
                window.toastr.error('<b>Sisfisico</b> não encontrado!')
                setDescricao('')
                setItem_id('')
                setCodigoFilhoMV('')
                setCodigoMestreMV('')

            })

        }
        
    }

    const limpaDados = () => {

        setEmpenho('')
        setDataEmissao('')
        setQuantidade('')
        setValorUnit('')
        setValorTotal('')
        setUnidFornecimento('')
        setNumeroProtocolo('')
        setModalidade('')
        setCnpj('')
        setFornecedor('')
        setSiafisicoPregao('')
        setDescricao('')
        setItem_id('')
        setCodigoMestreMV('')
        setCodigoFilhoMV('')

    }


    useEffect(() => {

        //window.$('[href="#tabs-4-tab-1x2"]').tab('show');
        limpaDados()
        setItem_id(props.item_id)
        carrega()

    }, [empenho_id, item_id])

    return (

        <div>

            <div class='modal fade modal-EmpenhoEdit'
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Empenho</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">NE</label>
                                        <input type="text" className="form-control" value={empenho} style={{ backgroundColor: '#ebd1d1', textTransform: 'uppercase'}} maxLength={12} onChange={event => setEmpenho(event.target.value)}/>
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Emissão</label>
                                        <InputMask mask="99/99/9999" type="text" className="form-control" value={dataEmissao} onChange={event => setDataEmissao(event.target.value)} />
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Unid. Fornecimento</label>
                                        <input type="text" className="form-control" value={unidFornecimento} onChange={event => setUnidFornecimento(event.target.value)} style={{ textTransform: 'uppercase'}} />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Quantidade</label>
                                        <input type="text" className="form-control" value={quantidade} onChange={event => setQuantidade(event.target.value)} 
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} 
                                        />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Valor Unitário</label>
                                        <CurrencyInput type="text" className="form-control" value={valorUnit} onChange={event => setValorUnit(event.target.value)} placeholder="0,00" />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Valor Total</label>
                                        <CurrencyInput type="text" className="form-control" value={valorTotal} onChange={event => setValorTotal(event.target.value)} placeholder="0,00" />
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Processo</label>
                                        <input type="text" className="form-control" value={numeroProtocolo} onChange={event => setNumeroProtocolo(event.target.value)} />
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Modalidade</label>
                                        <input type="text" className="form-control" value={modalidade} onChange={event => setModalidade(event.target.value)} />
                                    </fieldset>
                                </div>


                                <div className="col-md-12">
                                    <br/>
                                    <b>Fornecedor</b>
                                    
                                    <br/><br/>
                                </div>

                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">CNPJ</label>
                                        <InputMask mask="99.999.999/9999-99" type="text" className="form-control" value={cnpj} onChange={event => setCnpj(event.target.value)} style={{ backgroundColor: '#ebebd1' }}                                       
                                        onBlur={event => infoCNPJ()}
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-8">
                                    <fieldset className="form-group">
                                        <label className="form-label">Razão Social</label>
                                        <input type="text" className="form-control" value={fornecedor} onChange={event => setFornecedor(event.target.value)}  style={{ backgroundColor: '#ebebd1', textTransform: 'uppercase' }}/>
                                    </fieldset>
                                </div>


                                <div className="col-md-12">
                                    <br/>
                                    <b>Item</b>
                                    
                                    <br/><br/>
                                </div>



                                <div className="col-md-4">
                                        <fieldset className="form-group">
                                            <label className="form-label">Cód. Siafisico</label>
                                            <input type="text" className="form-control" value={siafisicoPregao} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setSiafisicoPregao(event.target.value);setCodigoMestreMV('');setCodigoFilhoMV('');}}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                
                                                }
                                            }} 
                                            onBlur={() => infoItem()}
                                            />
                                        </fieldset>
                                    </div>

                                    <div className="col-md-4">
                                        <fieldset className="form-group">
                                            <label className="form-label">Cód. Mestre MV</label>
                                            <input type="text" className="form-control" value={codigoMestreMV} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setCodigoMestreMV(event.target.value);setSiafisicoPregao('');setCodigoFilhoMV('');}}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} 
                                            onBlur={() => infoItem()}
                                            />
                                        </fieldset>
                                    </div>

                                    <div className="col-md-4">
                                        <fieldset className="form-group">
                                            <label className="form-label">Cód. Filho MV</label>
                                            <input type="text" className="form-control" value={codigoFilhoMV} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setCodigoFilhoMV(event.target.value);setSiafisicoPregao('');setCodigoMestreMV('');}}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} 
                                            onBlur={() => infoItem()}
                                            />
                                        </fieldset>
                                    </div>
                                
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Descrição</label>
                                        <textarea type="text" className="form-control" value={descricao}  rows="4" disabled={true} />
                                    </fieldset>
                                </div>

                            </div>

                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-danger" onClick={() => apagaEmpenho()} style={{ display: empenho_id ? 'table-row' : 'none' }}  disabled={ perfil == 'Administrador' ? false : true }>Apagar</button>
                            
                            <button type="button" className="btn btn-inline btn-success" onClick={() => salvaEmpenho()}  disabled={ perfil == 'Administrador' ? false : true }>Salvar</button>
                            
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    )

}

export default Modal