
import { useEffect, useMemo, useState } from 'react'
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import ModalImporta from '../modal/ModalImporta'
import ModalExporta from '../modal/ModalExporta'

const Importacoes = () => {



    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [

             
          {
            accessorKey: 'ad_new', //normal accessorKey
            header: 'Início',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          
          {
            accessorKey: 'tipo', //access nested data with dot notation
            header: 'Tipo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
           
          },
          {
            accessorKey: 'status', //access nested data with dot notation
            header: 'Status',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == 'Importado' ? 'success' : renderedCellValue == 'Erro' ? 'danger' : 'warning' }`}>{renderedCellValue}</span>
           
            ),
           
          },
         
          
        ],
        [],
    );



    const consulta = () => {

        api.get('listaImportacaoTodos').then((result) => {

            var data = result.data
            
            
            setResultado(data.map((rs) => {


                return {

                    ad_new: rs.ad_new,
                    tipo: rs.tipo,
                    status: rs.status,

                }


            }))

        }).catch((err) => {


            console.log(err.response)


        })


    }




    useEffect(() => {

        consulta()

    }, [])


    return (


        <div>

            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                                Importação/Exportação
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
        

                        <button type="button" class="btn btn-inline btn-primary" onClick={() => consulta()}>Atualizar status de importação</button>&nbsp;
                        <button type="button" class="btn btn-inline btn-info" onClick={() => {window.$('.modal-Importa').modal('show')}}>+ Importar dados</button>&nbsp;
                        <button type="button" class="btn btn-inline btn-warning" onClick={() => {window.$('.modal-Exporta').modal('show')}}>Exportar dados</button>


                                
                        <br/><br/>


                        <MaterialReactTable 
                            
                            columns={columns} 
                            data={resultado} 
                            localization={MRT_Localization_PT_BR}
                            //rowsPerPage={20}
                            //options={options}
                            initialState={{ density: 'compact' }}
                            muiTableHeadCellProps={{
                                //easier way to create media queries, no useMediaQuery hook needed.
                                sx: {
                                fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                },
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                    },
                                },
                            }}

                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {
                                //console.info(event, row.original.usuario_id);
                                //navigate(`/Usuario/${row.original.usuario_id}`)

                                },
                                sx: {
                                //cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                },
                            })}
                            
                        />


                        </div>
                    </div>                
                </div>
            </div>



            <ModalImporta />
            <ModalExporta />


        </div>



    )

}

export default Importacoes

