import { useEffect, useState } from 'react'
import CurrencyInput from '../components/CurrencyInput'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'
import InputMask from 'react-input-mask'
import moment from 'moment'

const ItemDet = (props) => {

    const item_id = props.item_id

    const perfil = sessionStorage.getItem('perfil')
    const somenteLeitura = sessionStorage.getItem('somenteLeitura')


    const [custoMedio, setCustoMedio] = useState()
    const [codigoMestreMV, setCodigoMestreMV] = useState('')
    const [siafisicoPregao, setSiafisicoPregao] = useState('')
    const [siafisicoATA, setSiafisicoATA] = useState('')
    const [descricao, setDescricao] = useState('')
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [grupo, setGrupo] = useState('')
    const [subGrupo, setSubGrupo] = useState('')
    
    const [codigoProtheus, setCodigoProtheus] = useState('')
    const [codigoFilhoMV, setCodigoFilhoMV] = useState('')

    //possuiSubstituto bit, prioridade bit, possuiAta bit, numeroAta nvarchar(25), vencimentoAta date
    const [possuiSubstituto, setPossuiSubstituto ] = useState('')
    const [prioridade, setPrioridade] = useState('')
    const [possuiAta, setPossuiAta] = useState('')
    const [numeroAta, setNumeroAta] = useState('')
    const [vencimentoAta, setVencimentoAta] = useState('')
    const [padronizado, setPadronizado] = useState()


    const limpa = () => {

        setCodigoMestreMV('')
        setSiafisicoPregao('')
        setSiafisicoATA('')
        setDescricao('')
        setUnidFornecimento('')
        setGrupo('')
        setSubGrupo('')
        
        setCustoMedio()
        setCodigoProtheus('')
        setCodigoFilhoMV('')



        setPossuiSubstituto('')
        setPrioridade('')
        setPossuiAta('')
        setNumeroAta('')
        setVencimentoAta('')
        setPadronizado('')

    }


    const carrega = () => {


        if (item_id) {


            api.get(`item/${item_id}`).then((result) => {

                var data = result.data[0]

                console.log(data)

                setCodigoMestreMV(data.codigoMestreMV)
                setCodigoFilhoMV(data.codigoFilhoMV == null ? '' : data.codigoFilhoMV)
                setCodigoProtheus(data.codigoProtheus == null ? '' : data.codigoProtheus)
                setSiafisicoPregao(data.siafisicoPregao)
                setSiafisicoATA(data.siafisicoATA)
                setDescricao(data.descricao)
                setUnidFornecimento(data.unidFornecimento)
                setGrupo(data.grupo)
                setSubGrupo(data.subGrupo)
                
                setCustoMedio(data.custoMedio == null ? '' : fmtMoney(data.custoMedio, 2))

                setPossuiSubstituto(data.possuiSubstituto == true ? '1' : data.possuiSubstituto == false ? '0' : '')
                setPadronizado(data.padronizado == true ? '1' : data.padronizado == false ? '0' : '')

                setPrioridade(data.prioridade == true ? '1' : data.prioridade == false ? '0' : '')
                setPossuiAta(data.possuiAta == true ? '1' : data.possuiAta == false ? '0' : '')
                setNumeroAta(data.numeroAta)
                setVencimentoAta(data.vencimentoAta)


            }).catch((err) => {

                console.log(err.response)

            })


        }


    }


    const salvar = () => {


        if (possuiSubstituto == '') {

            window.toastr.error('<b>Possui substituto</b> não pode ficar em branco!')
            return false

        }


        if (prioridade == '') {

            window.toastr.error('<b>Crítico</b> não pode ficar em branco!')
            return false

        }

        if (padronizado == '') {

            window.toastr.error('<b>Padronizado</b> não pode ficar em branco!')
            return false

        }


        if (possuiAta == '') {

            window.toastr.error('<b>Possui Ata</b> não pode ficar em branco!')
            return false

        }

        if (siafisicoPregao == '') {

            window.toastr.error('<b>Siafisico</b> não pode ficar em branco!')
            return false

        }


        if (unidFornecimento == '') {

            window.toastr.error('<b>Unidade Fornecimento</b> não pode ficar em branco!')
            return false

        }


        var vencimentoAta_msk = ''


        if (possuiAta == '1') {

            

            if (moment(vencimentoAta, "DD/MM/YYYY", true).isValid() == false) {

                window.toastr.error('<b>Data de vencimento da ata</b> inválida!');
                return false;

            }

            var vencimentoAta_array = vencimentoAta.split('/')
            var vencimentoAta_msk = `${vencimentoAta_array[2]}-${vencimentoAta_array[1]}-${vencimentoAta_array[0]}`

            

        }




        const dataPost = {

            //custoMedio: custoMedio != null ? parseFloat(custoMedio.replaceAll('.', '').replaceAll(',', '.').replaceAll('R$', '')) : null,
            codigoMestreMV: codigoMestreMV == null ? '' : codigoMestreMV,
            codigoFilhoMV: codigoFilhoMV == null ? '' : codigoFilhoMV,
            siafisicoPregao: siafisicoPregao,
            codigoProtheus: codigoProtheus == null ? '' : codigoProtheus,
            descricao: descricao,
            unidFornecimento: unidFornecimento,
            grupo: grupo,
            subGrupo: subGrupo,
            possuiSubstituto: possuiSubstituto == '1' ? true : possuiSubstituto == '0' ? false : null,
            prioridade: prioridade == '1' ? true : prioridade == '0' ? false : null,
            possuiAta: possuiAta == '1' ? true : possuiAta == '0' ? false : null,
            padronizado: padronizado == '1' ? true : padronizado == '0' ? false : null,
            numeroAta: numeroAta,
            vencimentoAta: vencimentoAta_msk,
           

        }


        //console.log(dataPost)


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar o item?',
		    buttons: {
		        Sim: function () {


                    if (item_id) {


                        api.put(`item/${item_id}`, dataPost).then((result) => {

                            console.log(result.data)
                            window.$('.modal-CatalogoEdit').modal('hide')
                            window.toastr.success('Registro salvo com sucesso!')
                            props.carregaGrupo()
                            props.carregaItens()
                            //limpa()

                        }).catch((err) => {

                            console.log(err.response)

                        })


                    } else {


                        api.post('item', dataPost).then((result) => {

                            console.log(result.data)
                            var data = result.data[0]
                            //setItem_id(data.item_id)
                            window.toastr.success('Registro salvo com sucesso!')
                            window.$('.modal-CatalogoEdit').modal('hide')
    
                            props.carregaGrupo()
                            props.carregaItens()
                            //limpa()
    
                        }).catch((err) => {
    
                            console.log(err)
    
                        })


                    }

                    

                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });



    }


    const apagar = () => {

            window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar?',
		    buttons: {
		        Sim: function () {




                    api.delete(`item/${item_id}`).then((result) => {

                        console.log(result.data)
                        window.toastr.success('Registro apagado com sucesso!')
                        window.$('.modal-CatalogoEdit').modal('hide')

                        props.carregaGrupo()
                        props.carregaItens()
                        
                        //setDataAtualiza(Math.random())

                        //props.consultaTotalVinculosPendentes()

                    }).catch((err) => {

                        console.log(err.response)

                    })




                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }



    const handleChange = (e) => {
        e.preventDefault();
    }


    useEffect(() => {

        limpa()
        carrega()

    }, [item_id])



    return (

        <div>


                <div className="row">
                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Siafisico</label>
                            <input type="text" className="form-control" value={siafisicoPregao} onChange={event => setSiafisicoPregao(event.target.value)} maxLength={25} style={{ backgroundColor: '#e0cecc'}} />
                        </fieldset>
                    </div>

                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Mestre MV</label>
                            <input type="text" className="form-control" value={codigoMestreMV} onChange={event => setCodigoMestreMV(event.target.value)} maxLength={25} />
                        </fieldset>
                    </div>
                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Filho MV</label>
                            <input type="text" className="form-control" value={codigoFilhoMV} onChange={event => setCodigoFilhoMV(event.target.value)} maxLength={25} />
                        </fieldset>
                    </div>
                   

                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Protheus</label>
                            <input type="text" className="form-control" value={codigoProtheus} onChange={event => setCodigoProtheus(event.target.value)} maxLength={25} />
                        </fieldset>
                    </div>

                    <div className="col-md-4" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Ata</label>
                            <input type="text" className="form-control" value={siafisicoATA} onChange={event => setSiafisicoATA(event.target.value)} maxLength={25} />
                        </fieldset>
                    </div>
                    <div className="col-md-12">
                        <fieldset className="form-group">
                            <label className="form-label">Descrição</label>
                            <textarea className="form-control" rows="5" value={descricao} onChange={event => setDescricao(event.target.value)} maxLength={2000} />
                        </fieldset>
                    </div>
                    <div className="col-md-4">
                        <fieldset className="form-group">
                            <label className="form-label">Unidade Fornecimento</label>
                            <input type="text" className="form-control" value={unidFornecimento} onChange={event => setUnidFornecimento(event.target.value)} maxLength={25} />
                        </fieldset>
                    </div>
                    <div className="col-md-4">
                        <fieldset className="form-group">
                            <label className="form-label">Grupo</label>
                            <input type="text" className="form-control" value={grupo} onChange={event => setGrupo(event.target.value)} maxLength={50} />
                        </fieldset>
                    </div>
                    <div className="col-md-4">
                        <fieldset className="form-group">
                            <label className="form-label">Subgrupo</label>
                            <input type="text" className="form-control" value={subGrupo} onChange={event => setSubGrupo(event.target.value)} maxLength={50} />
                        </fieldset>
                    </div>


                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Possui substituto</label>
                            <select className="form-control" value={possuiSubstituto} onChange={event => setPossuiSubstituto(event.target.value)} >
                                <option value="">[Selecione]</option>
                                <option value='1'>Sim</option>
                                <option value='0'>Não</option>
                            </select>
                        </fieldset>
                    </div>


                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Crítico</label>
                            <select className="form-control" value={prioridade} onChange={event => setPrioridade(event.target.value)} >
                                <option value="">[Selecione]</option>
                                <option value='1'>Sim</option>
                                <option value='0'>Não</option>
                            </select>
                        </fieldset>
                    </div>

                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Possui Ata</label>
                            <select className="form-control" value={possuiAta} onChange={event => setPossuiAta(event.target.value)} >
                                <option value="">[Selecione]</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </select>
                        </fieldset>
                    </div>

                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Padronizado</label>
                            <select className="form-control" value={padronizado} onChange={event => setPadronizado(event.target.value)} >
                                <option value="">[Selecione]</option>
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </select>
                        </fieldset>
                    </div>



                    <div className="col-md-6" style={{ display: possuiAta == '1' ? 'table-row' : 'none' }}>
                        <fieldset className="form-group">
                            <label className="form-label">Número Ata</label>
                            <input className="form-control" value={numeroAta} onChange={event => setNumeroAta(event.target.value)} />
                                
                        </fieldset>
                    </div>

                    <div className="col-md-6" style={{ display: possuiAta == '1' ? 'table-row' : 'none' }}>
                        <fieldset className="form-group">
                            <label className="form-label">Vencimento Ata</label>
                            <InputMask mask="99/99/9999" className="form-control" value={vencimentoAta} onChange={event => setVencimentoAta(event.target.value)} />
                                
                        </fieldset>
                    </div>
                    
                    <div className="col-md-6" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label">Custo médio R$</label>
                            <CurrencyInput type="text" className="form-control" value={custoMedio} onChange={event => setCustoMedio(event.target.value)}  
                            style={{ backgroundColor: '#eaeddd' }}
                            onCut={handleChange}
                            onCopy={handleChange}
                            onPaste={handleChange}
                            />
                        </fieldset>
                    </div>
                    <div className="col-md-12">
                        <br/>
                        <div align="right">
                            <button type="button" className="btn btn-inline btn-danger"  style={{ display: item_id ? 'table-row' : 'none'}} onClick={() => apagar()} disabled={ perfil == 'Administrador' || somenteLeitura == 'true' ? false : true }>Apagar</button>&nbsp;
                            <button type="button" className="btn btn-inline btn-success"  onClick={() => salvar()} disabled={ perfil == 'Administrador' || somenteLeitura == 'true' ? false : true }>Salvar</button>
                        </div>
                    </div>
                                    

                </div>


        </div>
    )

}

export default ItemDet