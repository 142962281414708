import { useEffect, useState } from "react"
import InputMask from 'react-input-mask'
import CurrencyInput from '../components/CurrencyInput'
import CurrencyInput4 from '../components/CurrencyInput4'
import { fmtMoney } from "../functions/generalFunctions"
import api from "../components/api"
import moment from 'moment'

const Modal = (props) => {

    const processo_id = props.processo_id
    const perfil = sessionStorage.getItem('perfil')

    const [siafisicoPregao, setSiafisicoPregao] = useState('')    
    const [descricao, setDescricao] = useState('')
    const [item_id, setItem_id] = useState('')
    const [processo, setProcesso] = useState('')
    const [quantidade, setQuantidade] = useState('')
    const [valorPesqPreco, setValorPesqPreco] = useState('')
    const [valorTotalPrevisto, setValorTotalPrevisto] = useState('')
    const [modalidade, setModalidade] = useState('')
    const [dataInicio, setDataInicio] = useState('')
    const [dataPregao, setDataPregao] = useState('')

    const [codigoMestreMV, setCodigoMestreMV] = useState('')
    const [codigoFilhoMV, setCodigoFilhoMV] = useState('')


    const [listaModalidadesProcesso, setListaModalidadesProcesso] = useState([])

    const salva = () => {


        if (moment(dataInicio, "DD/MM/YYYY", true).isValid() == false) {

			window.toastr.error('<b>Data início</b> inv&aacute;lida!');
			return false;

		}

        if (dataPregao != '' && dataPregao != null) {


            if (moment(dataPregao, "DD/MM/YYYY", true).isValid() == false) {

                window.toastr.error('<b>Data pregão</b> inv&aacute;lida!');
                return false;
    
            } else {

                var dataPregao_array = dataPregao.split('/')
                var dataPregao_ref = dataPregao_array[2] + '-' + dataPregao_array[1] + '-' + dataPregao_array[0]

            }


        }

        

        
        if (item_id == '') {

            window.toastr.error('<b>Siafisico</b> não pode ficar em branco!')
            return false

        }

          
        if (processo == '') {

            window.toastr.error('<b>Processo</b> não pode ficar em branco!')
            return false

            
        }

        if (quantidade == '') {

            window.toastr.error('<b>Quantidade</b> não pode ficar em branco!')
            return false

        }

        if (valorPesqPreco == '') {

            window.toastr.error('<b>Valor Pesquisa de Preço</b> não pode ficar em branco!')
            return false

        }


        if (modalidade == '') {

            window.toastr.error('<b>Modalidade</b> não pode ficar em branco!')
            return false

        }

        var dataInicio_array = dataInicio.split('/')
        var dataInicio_ref = dataInicio_array[2] + '-' + dataInicio_array[1] + '-' + dataInicio_array[0]



        var dataPost = {

            item_id: item_id,
            processo: processo,
            quantidade: quantidade,
            valorPesqPreco: parseFloat(valorPesqPreco.replaceAll('.', '.').replaceAll(',', '.')),
            modalidade: modalidade,
            dataInicio: dataInicio_ref,
            dataPregao: dataPregao_ref,

        }

        //console.log(dataPost)


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {



                    if (processo_id) {


                            
                        api.put(`processo/${processo_id}`, dataPost).then((result) => {

                            //console.log(result.data)
                            //limpa()
                            window.toastr.success('Registro salvo com sucesso!')
                            props.carregaProcessos()
                            window.$('.modal-Processo').modal('hide')
                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })




                    } else {



                        api.post(`processo`, dataPost).then((result) => {

                            //console.log(result.data)

                            //limpa()
                            window.toastr.success('Registro salvo com sucesso!')
                            props.carregaProcessos()
                            window.$('.modal-Processo').modal('hide')
                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })


                    }


                              

                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


        


    }

    const limpa = () => {

        setSiafisicoPregao('')
        setItem_id('')
        setDescricao('')
        setProcesso('')
        setQuantidade('')
        setValorPesqPreco('')
        setValorTotalPrevisto('')
        setModalidade('')
        setDataInicio('')
        setDataPregao('')
        setCodigoMestreMV('')
        setCodigoFilhoMV('')

    }


    const infoItem = () => {

        if (siafisicoPregao != '' || codigoMestreMV != '' || codigoFilhoMV != '') {


            var dataPost = {

                siafisicoPregao: siafisicoPregao,
                codigoMestreMV: codigoMestreMV,
                codigoFilhoMV: codigoFilhoMV,

            }

            api.post('infoItem', dataPost).then((result) => {

                if (result.data) {

                    setDescricao(result.data[0].descricao)
                    setSiafisicoPregao(result.data[0].siafisicoPregao)
                    setCodigoMestreMV(result.data[0].codigoMestreMV)
                    setCodigoFilhoMV(result.data[0].codigoFilhoMV)
                    setItem_id(result.data[0].item_id)

                } else {

                    window.toastr.error('<b>Sisfisico</b> não encontrado!')
                    setDescricao('')
                    setItem_id('')
                    setCodigoFilhoMV('')
                    setCodigoMestreMV('')

                }

                

            }).catch((err) => {

                console.log(err.response)
                window.toastr.error('<b>Sisfisico</b> não encontrado!')
                setDescricao('')
                setItem_id('')
                setCodigoFilhoMV('')
                setCodigoMestreMV('')

            })

        }
        
    }




    const carrega = () => {

        if (processo_id) {


            api.get(`processo/${processo_id}`).then((result) => {

                //console.log(result.data)

                const data = result.data[0]
                
                setSiafisicoPregao(data.siafisicoPregao)        
                setCodigoMestreMV(data.codigoMestreMV)
                setCodigoFilhoMV(data.codigoFilhoMV)        
                setDescricao(data.descricao)
                setItem_id(data.item_id)
                setProcesso(result.data[0].processo)
                setQuantidade(result.data[0].quantidade)
                setValorPesqPreco(fmtMoney(result.data[0].valorPesqPreco, 4).replaceAll('R$', ''))
                setValorTotalPrevisto(fmtMoney(result.data[0].valorTotalPrevisto, 2).replaceAll('R$', ''))
                setModalidade(result.data[0].modalidade)
                setDataInicio(result.data[0].dataInicio)
                setDataPregao(result.data[0].dataPregao)

            }).catch((err) => {


                console.log(err.response)

            })

        }



    }

    const carregaModalidades = () => {


        api.get(`modalidadesProcesso`).then((result) => {


            setListaModalidadesProcesso(result.data.map((rs) => 
            
                <option value={rs.modalidadeProcesso}>{rs.modalidadeProcesso}</option>
            ))

        }).catch((err) => {

            console.log(err.response)

        })


    }


    const apaga = () => {


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar o processo?',
		    buttons: {
		        Sim: function () {

            
                        api.delete(`processo/${processo_id}`).then((result) => {
            
                            //if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro apagado com sucesso!')
                                limpa()
                                window.$('#modalProcesso').modal('hide')
                                props.carregaProcessos()

                            //}

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                   
                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    const calculaTotalPrevisto = () => {

        var totalPrevisto = 0

        if (quantidade && valorPesqPreco) {

            var totalPrevisto = parseFloat(quantidade) * parseFloat(valorPesqPreco.replaceAll('.', '').replaceAll(',', '.'))
            
            setValorTotalPrevisto(totalPrevisto.toLocaleString('pt-BR'))

        }
        
    }


    useEffect(() => {

        limpa()
        carrega()
        carregaModalidades()

    }, [processo_id])


    useEffect(() => {

        if (modalidade != 'Pregão') {

            setDataPregao('')

        }

    }, [modalidade])


    return (


        <div>

                <div class={`modal fade modal-Processo`}
                    id="modalProcesso"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            
                            <h4 class="modal-title" id="myModalLabel">Processo {processo_id}</h4>
                            </div>
                            <div class="modal-body">


                                <div className="row">
                                    <div className="col-md-4">
                                        <fieldset className="form-group">
                                            <label className="form-label">Cód. Siafisico</label>
                                            <input type="text" className="form-control" value={siafisicoPregao} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setSiafisicoPregao(event.target.value);setCodigoMestreMV('');setCodigoFilhoMV('');}}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                
                                                }
                                            }} 
                                            onBlur={() => infoItem()}
                                            />
                                        </fieldset>
                                    </div>

                                    <div className="col-md-4">
                                        <fieldset className="form-group">
                                            <label className="form-label">Cód. Mestre MV</label>
                                            <input type="text" className="form-control" value={codigoMestreMV} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setCodigoMestreMV(event.target.value);setSiafisicoPregao('');setCodigoFilhoMV('');}}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} 
                                            onBlur={() => infoItem()}
                                            />
                                        </fieldset>
                                    </div>

                                    <div className="col-md-4">
                                        <fieldset className="form-group">
                                            <label className="form-label">Cód. Filho MV</label>
                                            <input type="text" className="form-control" value={codigoFilhoMV} style={{ backgroundColor: '#d7e5f7'}} onChange={event => {setCodigoFilhoMV(event.target.value);setSiafisicoPregao('');setCodigoMestreMV('');}}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} 
                                            onBlur={() => infoItem()}
                                            />
                                        </fieldset>
                                    </div>
                                
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Descrição</label>
                                        <textarea type="text" className="form-control" value={descricao}  rows="4" disabled={true} />
                                    </fieldset>
                                </div>


                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Data início</label>
                                        <InputMask mask="99/99/9999" type="text" className="form-control" value={dataInicio} onChange={event => setDataInicio(event.target.value)} />
                                    </fieldset>
                                </div>

                                <div className="col-md-9">
                                    <fieldset className="form-group">
                                        <label className="form-label">Processo</label>
                                        <input type="text" className="form-control" value={processo} onChange={event => setProcesso(event.target.value)} />
                                    </fieldset>
                                </div>

                                <div className="col-md-9">
                                    <fieldset className="form-group">
                                        <label className="form-label">Modalidade</label>
                                        <select className="form-control" value={modalidade} onChange={event => setModalidade(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            {listaModalidadesProcesso}
                                        </select>
                                    </fieldset>
                                </div>

                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Data pregão</label>
                                        <InputMask mask="99/99/9999" type="text" className="form-control" value={dataPregao} disabled={ modalidade == 'Pregão' ? false : true} onChange={event => setDataPregao(event.target.value)} />
                                    </fieldset>
                                </div>

                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Quantidade</label>
                                        <input type="text" className="form-control" value={quantidade} onChange={event => setQuantidade(event.target.value)} 
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} 
                                        onBlur={() => calculaTotalPrevisto()}
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Valor Pesquisa de Preço</label>
                                        <CurrencyInput4 type="text" className="form-control" value={valorPesqPreco} onChange={event => setValorPesqPreco(event.target.value)} placeholder="0,0000"
                                        onBlur={() => calculaTotalPrevisto()}                                        
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Valor Total Previsto</label>
                                        <CurrencyInput type="text" className="form-control" value={valorTotalPrevisto} onChange={event => setValorTotalPrevisto(event.target.value)} placeholder="0,00" disabled />
                                    </fieldset>
                                </div>

                            </div>

                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-danger" onClick={() => apaga()} style={{ display: processo_id ? 'table-row' : 'none' }}  disabled={ perfil == 'Administrador' ? false : true }>Apagar</button>
                            
                            <button type="button" className="btn btn-inline btn-success" onClick={() => salva()}  disabled={ perfil == 'Administrador' ? false : true }>Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )

}

export default Modal