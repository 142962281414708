import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import api from '../components/api'
import { fmtMoney, sleep } from '../functions/generalFunctions'
import moment from 'moment'
import ModalAlteracoesMeta from '../modal/ModalAlteracoesMeta'

const Item = () => {

    const { slug } = useParams()

    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()
    const [ano, setAno] = useState(anoAtual.toString())

    const [itemDescri, setItemDescri] = useState('')
    const [resultadoBusca, setResultadoBusca] = useState('')
    const [codigoMestreMV, setCodigoMestreMV] = useState('')
    const [codigoFilhoMV, setCodigoFilhoMV] = useState('')
    

    const [siafisicoPregao, setSiafisicoPregao] = useState('')
    const [descricao, setDescricao] = useState('')
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [item_id, setItem_id] = useState()

    const [resultado, setResultado] = useState([])
    const [resultadoProcessos, setResultadoProcessos] = useState([])

    const [totalQtdeEntregue, setTotalQtdeEntregue] = useState(0)
    const [totalQtdeEntregar, setTotalQtdeEntregar] = useState(0)
    const [percQtdeEntregue, setPercQtdeEntregue] = useState(0)
    

    const [totalQtde, setTotalQtde] = useState(0)
    const [mediaValorUnit, setMediaValorUnit] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)

    const [resultadoEmpenhos, setResultadoEmpenhos] = useState([])
    const [resultadoEntregas, setResultadoEntregas] = useState([])
    const [resultadoEntregasMensais, setResultadoEntregasMensais] = useState([])
    const [corStatus, setCorStatus] = useState('#FFF')


    const [statusConsumo, setStatusConsumo] = useState('')


    const [consumoJan, setConsumoJan] = useState(0)
    const [consumoFev, setConsumoFev] = useState(0)
    const [consumoMar, setConsumoMar] = useState(0)
    const [consumoAbr, setConsumoAbr] = useState(0)
    const [consumoMai, setConsumoMai] = useState(0)
    const [consumoJun, setConsumoJun] = useState(0)
    const [consumoJul, setConsumoJul] = useState(0)
    const [consumoAgo, setConsumoAgo] = useState(0)
    const [consumoSet, setConsumoSet] = useState(0)
    const [consumoOut, setConsumoOut] = useState(0)
    const [consumoNov, setConsumoNov] = useState(0)
    const [consumoDez, setConsumoDez] = useState(0)
    const [totalConsumo, setTotalConsumo] = useState(0)


    const [estoque, setEstoque] = useState(0)
    const [consumoMedio, setConsumoMedio] = useState(0)
    const [autonomia, setAutonomia] = useState(0)
    const [autonomiaDiaria, setAutonomiaDiaria] = useState('')



    const [entregaJan, setEntregaJan] = useState(0)
    const [entregaFev, setEntregaFev] = useState(0)
    const [entregaMar, setEntregaMar] = useState(0)
    const [entregaAbr, setEntregaAbr] = useState(0)
    const [entregaMai, setEntregaMai] = useState(0)
    const [entregaJun, setEntregaJun] = useState(0)
    const [entregaJul, setEntregaJul] = useState(0)
    const [entregaAgo, setEntregaAgo] = useState(0)
    const [entregaSet, setEntregaSet] = useState(0)
    const [entregaOut, setEntregaOut] = useState(0)
    const [entregaNov, setEntregaNov] = useState(0)
    const [entregaDez, setEntregaDez] = useState(0)
    const [entregaMedia, setEntregaMedia] = useState(0)
    const [totalEntregue, setTotalEntregue] = useState(0)


    

    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }

    useEffect(() => {

        if (slug) {

            setItem_id(parseInt(slug))

            api.get(`item/${parseInt(slug)}`).then((result) => {

                var data = result.data[0]

                //console.log(data)

                setCodigoMestreMV(data.codigoMestreMV)
                setCodigoFilhoMV(data.codigoFilhoMV == null ? '' : data.codigoFilhoMV)
                setSiafisicoPregao(data.siafisicoPregao)
                
                setDescricao(data.descricao)
                setUnidFornecimento(data.unidFornecimento)
                /*
                setGrupo(data.grupo)
                setSubGrupo(data.subGrupo)
                
                setCustoMedio(data.custoMedio == null ? '' : fmtMoney(data.custoMedio, 2))

                setPossuiSubstituto(data.possuiSubstituto == true ? '1' : data.possuiSubstituto == false ? '0' : '')
                setPrioridade(data.prioridade == true ? '1' : data.prioridade == false ? '0' : '')
                setPossuiAta(data.possuiAta == true ? '1' : data.possuiAta == false ? '0' : '')
                setNumeroAta(data.numeroAta)
                setVencimentoAta(data.vencimentoAta)
                */


            }).catch((err) => {

                console.log(err.response)

            })

        }

    }, [slug])


    const carrega = () => {

        if (item_id) {

            api.get(`planejamentoItem/${item_id}/${ano}`).then((result) => {

                //console.log(result.data, '!!')

                var data = result.data

                //console.log(data)
                
                setStatusConsumo(data[0].status)
                setCorStatus(data[0].corStatus)
    
                setResultado(data.map((rs) => 
                
                    <tr style={{ fontSize: '11px'}}>

                        <td style={{ textAlign: 'center'}}>
                            
                            {rs.ano}
    
                        </td>
                       
                        <td style={{ textAlign: 'right'}}>
                            
                            <Link style={{ cursor: 'pointer'}} onClick={() => {window.$('.modal-AlteracoesMeta').modal('show')}}>{rs.metaConsumo == null ? '0' : fmtMoney(rs.metaConsumo).replaceAll('R$', '')}</Link>
    
                        </td>
                        
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.metaPlanejada == null ? '0' : fmtMoney(rs.metaPlanejada).replaceAll('R$', '')}
    
                        </td>
                        
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.estoque == null ? '0' : fmtMoney(rs.estoque).replaceAll('R$', '')}
    
                        </td>
                        
    
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.valorReferencial == null ? '0,00' : fmtMoney(rs.valorReferencial, 2)}
    
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.metaFinanceira == null ? '0,00' : fmtMoney(rs.metaFinanceira, 2)}
    
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.metaCompra == null ? '0' : fmtMoney(rs.metaCompra).replaceAll('R$', '')}
    
                        </td>

                        
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.metaExecutada == null ? '0' : fmtMoney(rs.metaExecutada).replaceAll('R$', '')}
    
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.metaExecutar == null ? '0' : fmtMoney(rs.metaExecutar).replaceAll('R$', '')}
    
                        </td>
                        <td style={{ textAlign: 'center', backgroundColor: rs.conclusao == 'Meta superada' ? '#d0f1f5' : rs.conclusao == 'Meta executada' ? '#d0e3f5' : '#e9f5d0' }}>
                            
                            {rs.conclusao}
    
                        </td>

                        
                    </tr>
                
                ))
    
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })

        }

        

    }


    const carregaEntregas = () => {

        if (item_id) {


            api.get(`entregaItem/${item_id}/${ano}`).then((result) => {

                //console.log(result.data)

                setResultadoEntregas(result.data.map((rs) => 
                
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            {rs.dataEntrega}
                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            {fmtMoney(rs.quantidade).toString().replaceAll('R$', '')}
                        </td>                        
                    </tr>
                
                ))


            }).catch((err) => {


                console.log(err.response)


            })

        }

        
    }


    const carregaProcessos = () => {

        if (item_id && ano) {


            api.get(`itemProcessos/${item_id}/${ano}`).then((result) => {

                //var dataTotais = result.data[1][0]
                
                //setTotalQtde(fmtMoney(dataTotais.totalQtde, 0).replaceAll('R$', ''))
                //setMediaValorUnit(fmtMoney(dataTotais.mediaValorUnit, 2))
                //setValorTotal(fmtMoney(dataTotais.valorTotal, 2))
    
                //console.log(result.data)
    
                var data = result.data
                
    
                setResultadoProcessos(data.map((rs) => 
                
                    <tr style={{ fontSize: '11px', fontSize: '11px'}} >
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            {rs.processo}

                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.quantidade == null ? '0' : fmtMoney(rs.quantidade, 0).replaceAll('R$', '')}
                            
                        </td>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            {rs.unidFornecimento}

                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.valorPesqPreco == null ? '0' : fmtMoney(rs.valorPesqPreco, 2)}
    
                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.valorTotalPrevisto == null ? '0' : fmtMoney(rs.valorTotalPrevisto, 2)}
    
                        </td>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            {rs.dataInicio}
    
                        </td>
                       
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            <span class="label label-primary" style={{ fontSize: '11px'}}>{rs.modalidade}</span>
                            &nbsp;{rs.modalidade == 'Pregão' && rs.dataPregao != null ? `em ${rs.dataPregao}` : ''}
    
                        </td>
                      
                        
                    </tr>
                
                ))
    
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })

            

        }

        

    }



    const carregaEmpenhos = () => {

        //console.log(`empenhoItem/${item_id}/${ano}`)
        setTotalQtdeEntregar(0)
        setPercQtdeEntregue(0)

        api.get(`empenhoItem/${item_id}/${ano}`).then((result) => {

            var dataTotais = result.data[1][0]

            //console.log(dataTotais)

            setTotalQtde(fmtMoney(dataTotais.totalQtde, 0).replaceAll('R$', ''))
            setTotalQtdeEntregue(fmtMoney(dataTotais.totalQtdeEntregue, 0).replaceAll('R$', ''))
            setTotalQtdeEntregar(fmtMoney(dataTotais.totalQtdeEntregar, 0).replaceAll('R$', ''))
            setPercQtdeEntregue(dataTotais.percQtdeEntregue)
            setMediaValorUnit(fmtMoney(dataTotais.mediaValorUnit, 2))
            setValorTotal(fmtMoney(dataTotais.valorTotal, 2))


            var data = result.data[0]

            console.log(data)

            setResultadoEmpenhos(data.map((rs) => 
            
                <tr style={{ fontSize: '11px'}}>
                    <td style={{ textAlign: 'center', fontSize: '11px'}}>
                        
                        {rs.dataEmissao}
                        
                    </td>
                    <td style={{ textAlign: 'center', fontSize: '11px'}}>
                        
                        
                        <span class="label label-info" style={{ fontSize: '11px'}}>{rs.empenho}</span>
                        
                    </td>
                    <td style={{ textAlign: 'right', fontSize: '11px'}}>
                        
                        {rs.quantidade == null ? '0' : fmtMoney(rs.quantidade, 0).replaceAll('R$', '')}

                    </td>
                    <td style={{ textAlign: 'right', fontSize: '11px'}}>
                        
                        {rs.totalQtdeEntregue == null ? '0' : fmtMoney(rs.totalQtdeEntregue, 0).replaceAll('R$', '')}

                    </td>
                    <td style={{ textAlign: 'right', fontSize: '11px'}}>
                        
                        {rs.totalQtdeEntregar == null ? '0' : fmtMoney(rs.totalQtdeEntregar, 0).replaceAll('R$', '')}

                    </td>
                    <td style={{ textAlign: 'center', fontSize: '11px'}}>
                        
                        {rs.dataPrazoEntrega}

                    </td>
                    <td style={{ textAlign: 'center', fontSize: '11px'}}>
                        
                        {rs.dataEntrega}

                    </td>
                    <td style={{ textAlign: 'right', fontSize: '11px'}}>
                        
                        {rs.diasDoPrazoEntrega > 0 ? rs.diasDoPrazoEntrega : '0'}

                    </td>
                    <td style={{ textAlign: 'right', fontSize: '11px'}}>
                        
                        {rs.percQtdeEntregue}

                    </td>
                    <td style={{ textAlign: 'center', fontSize: '11px'}}>
                        
                        {rs.unidFornecimento}
                        
                    </td>
                    <td style={{ textAlign: 'right', fontSize: '11px'}}>
                        
                        {rs.valorUnit == null ? '0' : fmtMoney(rs.valorUnit, 2)}

                    </td>
                    <td style={{ textAlign: 'right', fontSize: '11px'}}>
                        
                        {rs.valorTotal == null ? '0' : fmtMoney(rs.valorTotal, 2)}

                    </td>
                   
                    
                </tr>
            
            ))


        }).catch((err) => {

            console.log(err.response)

        })


    }
    
    const carregaEntregasMensais = () => {

        if (ano && item_id) {


            api.get(`itemEstoque/${item_id}/${ano}`).then((result) => {

                //console.log(result.data)

                //setTotalEntregue(result.data[1][0].totalEntregue ? fmtMoney(result.data[1][0].totalEntregue, 0).toString().replaceAll('R$', '') : 0)
                //setEntregaMedia(result.data[1][0].entregaMedia ? fmtMoney(result.data[1][0].entregaMedia, 0).toString().replaceAll('R$', '') : 0)
                //setAutonomia(result.data[1][0].autonomia ? fmtMoney(result.data[1][0].autonomia, 0).toString().replaceAll('R$', '') : 0)
                //setEstoque(result.data[1][0].estoque ? fmtMoney(result.data[1][0].estoque, 0).toString().replaceAll('R$', '') : 0)
                

                result.data.map((rs) => {


                    switch (rs.mes) {

                        case 1:
                            setEntregaJan(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 2:
                            setEntregaFev(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 3:
                            setEntregaMar(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 4:
                            setEntregaAbr(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 5:
                            setEntregaMai(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 6:
                            setEntregaJun(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 7:
                            setEntregaJul(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 8:
                            setEntregaAgo(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 9:
                            setEntregaSet(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 10:
                            setEntregaOut(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 11:
                            setEntregaNov(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 12:
                            setEntregaDez(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                    }


                })

            }).catch((err) => {

                console.log(err.response)

            })


        }

    }

    const carregaConsumo = () => {


        if (ano && item_id) {


            api.get(`itemConsumo/${item_id}/${ano}`).then((result) => {

                //console.log(result.data[1], 'aqui')

                setTotalConsumo(result.data[1][0].totalConsumido ? fmtMoney(result.data[1][0].totalConsumido, 0).toString().replaceAll('R$', '') : 0)
                setConsumoMedio(result.data[1][0].consumoMedio ? fmtMoney(result.data[1][0].consumoMedio, 0).toString().replaceAll('R$', '') : 0)
                setAutonomia(`${result.data[1][0].autonomia ? fmtMoney(result.data[1][0].autonomia, 0).toString().replaceAll('R$', '') : 0} mes(es)`)
                setAutonomiaDiaria(`${result.data[1][0].autonomiaDiaria ? fmtMoney(result.data[1][0].autonomiaDiaria, 0).toString().replaceAll('R$', '') : 0} dia(s)`)
                setEstoque(result.data[1][0].estoque ? fmtMoney(result.data[1][0].estoque, 0).toString().replaceAll('R$', '') : 0)
                

                result.data[0].map((rs) => {


                    switch (rs.mes) {

                        case 1:
                            setConsumoJan(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 2:
                            setConsumoFev(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 3:
                            setConsumoMar(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 4:
                            setConsumoAbr(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 5:
                            setConsumoMai(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 6:
                            setConsumoJun(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 7:
                            setConsumoJul(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 8:
                            setConsumoAgo(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 9:
                            setConsumoSet(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 10:
                            setConsumoOut(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 11:
                            setConsumoNov(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 12:
                            setConsumoDez(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                    }


                })

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }



    useEffect(() => {

        if (itemDescri != '') {

            var dataPost = {

                itemDescri: itemDescri,

            }

            api.post('buscaItem', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoBusca(result.data.map((rs) =>
                
                    <Link onClick={() => {setItem_id(rs.item_id);setSiafisicoPregao(rs.siafisicoPregao);setDescricao(rs.descricao);setUnidFornecimento(rs.unidFornecimento);setCodigoMestreMV(rs.codigoMestreMV);setCodigoFilhoMV(rs.codigoFilhoMV)}}><font color='purple'>[{rs.siafisicoPregao}]</font> - {rs.descricao}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [itemDescri])



    const consulta = () => {


        //console.log(siafisicoPregao)
        setResultadoBusca('')
        setItemDescri('')
        
        //api.get()


    }


    const geraPDF = async () => {

        //console.log(`itemPDF/${item_id}/${ano}`)

        api.get(`itemPDF/${item_id}/${ano}`).then((result) => {

            //console.log(result.data)

            sleep(2000).then(() => {

                
                //window.open(`http://192.168.255.165:3378/pdf/${result.data.arquivo}`)
                window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)
                //window.open(`http://172.16.1.253:3378/pdf/${result.data.arquivo}`)
                
            })
            


        }).catch((err) => {

            console.log(err)

        })

    }


    useEffect(() => {

        carregaAnos()

    }, [])


    useEffect(() => {

        setConsumoJan(0)
        setConsumoFev(0)
        setConsumoMar(0)
        setConsumoAbr(0)
        setConsumoMai(0)
        setConsumoJun(0)
        setConsumoJul(0)
        setConsumoAgo(0)
        setConsumoSet(0)
        setConsumoOut(0)
        setConsumoNov(0)
        setConsumoDez(0)
        setTotalConsumo(0)
        
        setResultadoProcessos([])
        setResultadoEntregas([])
        setResultadoEmpenhos([])

        setTotalQtde(0)
        setMediaValorUnit(0)
        setValorTotal(0)
        setTotalQtdeEntregue(0)
        //setTotalQtdeEntregue(0)



        setEntregaJan(0)
        setEntregaFev(0)
        setEntregaMar(0)
        setEntregaAbr(0)
        setEntregaMai(0)
        setEntregaJun(0)
        setEntregaJul(0)
        setEntregaAgo(0)
        setEntregaSet(0)
        setEntregaOut(0)
        setEntregaNov(0)
        setEntregaDez(0)
        setEntregaMedia(0)
        setTotalEntregue(0)


        if (siafisicoPregao != '') {

            consulta()
            carrega()
            //carregaProcessos()
            //carregaEmpenhos()
            //carregaConsumo()

        } else {

            setResultadoBusca('')

        }


        carregaProcessos()
        carregaEmpenhos()
        carregaConsumo()
        //carregaEntregas()
        carregaEntregasMensais()

    }, [siafisicoPregao, ano])


    return (

        <div>

            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                            Ficha de Análise
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div class="col-sm-12 col-md-12">



                            <div className="row">

                                <div className="col-md-12 col-sm-6">
                                    <fieldset className="form-group">

                                        <textarea  placeholder='Busque pelo item...' className="form-control" value={itemDescri} onChange={event => setItemDescri(event.target.value)} />
                                        {resultadoBusca}

                                    </fieldset>
                                </div>



                                <div className="col-md-4" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Cód. Siafisico</label>
                                        <input type="text" className="form-control" value={siafisicoPregao} style={{ backgroundColor: '#d7e5f7'}} 
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-4" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Cód. Mestre MV</label>
                                        <input type="text" className="form-control" value={codigoMestreMV} style={{ backgroundColor: '#d7e5f7'}} 
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-4" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Cód. Filho MV</label>
                                        <input type="text" className="form-control" value={codigoFilhoMV} style={{ backgroundColor: '#d7e5f7'}} 
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Descrição</label>
                                        <textarea type="text" className="form-control" value={descricao}  rows="2" disabled={true} />
                                    </fieldset>
                                </div>

                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Unidade Fornecimento</label>
                                        <input type="text" className="form-control" value={unidFornecimento} style={{ backgroundColor: '#e0d1e8'}} 
                                        />
                                    </fieldset>
                                </div>




                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label" >Ano</label>
                                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                            {listaAnos}
                                        </select>
                                        
                                    </fieldset>
                                </div>

                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Planejamento</label>


                                        <table id="table-sm" class="table table-bordered table-hover table-sm">
                                            <thead>
                                                    <tr style={{ textAlign: 'center'}}>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                            
                                                            Ano

                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                            
                                                            Meta Consumo

                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                            
                                                            Meta mensal planejada

                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                            
                                                            Estoque ano anterior

                                                        </th>
                                                        
                                                        <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                            
                                                            Valor Referencial

                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                            
                                                            Meta Financeira

                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                            
                                                            Meta Compra

                                                        </th>
                                                        
                                                        <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                            
                                                            Meta Executada

                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                            
                                                            Meta Executar

                                                        </th>
                                                        <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                            
                                                            Situação

                                                        </th>
                                                        
                                                        
                                                    </tr>
                                                    
                                            </thead>
                                            <tbody>
                                            {resultado}
                                            </tbody>
                                        </table>
                                    </fieldset>
                                </div>


                                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Avaliação do plano</label>
                                        <input type="text" className="form-control" value={statusConsumo} style={{ backgroundColor: corStatus}} disabled
                                        />

                                    </fieldset>

                                </div>





                                </div>



                                
                                <div class="col-xl-6 dahsboard-column">
                                <section class="box-typical box-typical-dashboard panel panel-default scrollable" style={{ display: siafisicoPregao == '' ? 'none' : 'block', backgroundColor: '#f7f2eb'}}>
                                    <header class="box-typical-header panel-heading">
                                        <h3 class="panel-title">Fase 1 - Processos em andamento</h3>
                                    </header>
                                    <div class="box-typical-body panel-body">
                                        <table className="table table-striped table-bordered table-condensed">
                                            <thead>
                                            <tr>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}}><div >Processo</div></th>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Qtde</div></th>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Unid.</div></th>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Pesq. Preço</div></th>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Total Previsto</div></th>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Data autuação</div></th>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Modalidade</div></th>
                                                
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {resultadoProcessos}
                                            </tbody>
                                        </table>
                                    </div>
                                </section>

                                </div>
                                <div class="col-xl-6 dahsboard-column">
                                <section class="box-typical box-typical-dashboard panel panel-default scrollable" style={{ display: siafisicoPregao == '' ? 'none' : 'block', backgroundColor: '#ebf4f7'}}>
                                    <header class="box-typical-header panel-heading">
                                        <h3 class="panel-title">Fase 2 - Empenhados</h3>
                                    </header>
                                    <div className="box-typical-body panel-body">
                                        <table className="table table-striped table-bordered table-condensed">
                                            <thead>
                                                <tr>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Data emissão</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Empenho</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Qtde estim.</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Qtde entregue</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Qtde entregar</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Prazo entrega</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Data entrega</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Dias atraso entrega</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>% entregue</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Unid.</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Valor Unit.</div></th>
                                                    <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Valor Total</div></th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                            
                                                {resultadoEmpenhos}

                                            </tbody>

                                            <tfoot>
                                                <tr style={{ fontSize: '11px', textAlign: 'right'}}>
                                                    <th colspan="2" style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'center'}}>
                                                        Total
                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                        {totalQtde}
                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                        {totalQtdeEntregue}
                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                        {totalQtdeEntregar}
                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                        &nbsp;
                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                        &nbsp;
                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                        &nbsp;
                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                        {percQtdeEntregue}
                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4'}} >
                                                        &nbsp;
                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                        {mediaValorUnit}
                                                    </th>
                                                    <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4', textAlign: 'right'}}>
                                                        {valorTotal}
                                                    </th>
                                                    
                                                </tr>
                                            </tfoot>
                                            
                                        </table>
                                    </div>
                                </section>

                                </div>

                                    


                                </div>




                                        <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                                            <fieldset className="form-group">
                                        

                                                <table id="table-sm" class="table table-bordered table-hover table-sm">
                                                    <thead>
                                                            
                                                            <tr >
                                                                <th style={{ textAlign: 'left', fontSize: '11px', backgroundColor: '#EFEFEF'}}>

                                                                    &nbsp;

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Jan

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Fev

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                Mar

                                                                </th>
                                                                
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Abr

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Mai

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Jun

                                                                </th>
                                                                
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Jul

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Ago

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Set

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Out

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Nov

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                    
                                                                    Dez

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>

                                                                    Total

                                                                </th>
                                                                <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>

                                                                    Média

                                                                </th>
                                                                
                                                                
                                                                
                                                            </tr>
                                                            
                                                    </thead>
                                                    <tbody>
                                                        <tr style={{ fontSize: '11px', textAlign: 'right'}}>

                                                            <td style={{ textAlign: 'right', fontSize: '11px', backgroundColor: '#EFEFEF'}}>

                                                                <b>Consumo</b>

                                                            </td>
                                                          
                                                            <td>
                                                                {consumoJan}
                                                            </td>
                                                            <td>
                                                                {consumoFev}
                                                            </td>
                                                            <td>
                                                                {consumoMar}
                                                            </td>
                                                            <td>
                                                                {consumoAbr}
                                                            </td>
                                                            <td>
                                                                {consumoMai}
                                                            </td>
                                                            <td>
                                                                {consumoJun}
                                                            </td>
                                                            <td>
                                                                {consumoJul}
                                                            </td>
                                                            <td>
                                                                {consumoAgo}
                                                            </td>
                                                            <td>
                                                                {consumoSet}
                                                            </td>
                                                            <td>
                                                                {consumoOut}
                                                            </td>
                                                            <td>
                                                                {consumoNov}
                                                            </td>
                                                            <td>
                                                                {consumoDez}
                                                            </td>
                                                            <td>
                                                                {totalConsumo}
                                                            </td>
                                                            <td>
                                                                {consumoMedio}
                                                            </td>
                                                           
                                                        </tr>
                                                        <tr style={{ fontSize: '11px', textAlign: 'right'}}>
                                                            <td style={{ textAlign: 'right', fontSize: '11px', backgroundColor: '#EFEFEF'}}>

                                                                <b>Estoque</b>

                                                            </td>
                                                            <td>
                                                                {entregaJan}
                                                            </td>
                                                            <td>
                                                                {entregaFev}
                                                            </td>
                                                            <td>
                                                                {entregaMar}
                                                            </td>
                                                            <td>
                                                                {entregaAbr}
                                                            </td>
                                                            <td>
                                                                {entregaMai}
                                                            </td>
                                                            <td>
                                                                {entregaJun}
                                                            </td>
                                                            <td>
                                                                {entregaJul}
                                                            </td>
                                                            <td>
                                                                {entregaAgo}
                                                            </td>
                                                            <td>
                                                                {entregaSet}
                                                            </td>
                                                            <td>
                                                                {entregaOut}
                                                            </td>
                                                            <td>
                                                                {entregaNov}
                                                            </td>
                                                            <td>
                                                                {entregaDez}
                                                            </td>
                                                            <td colspan="2">&nbsp;</td>
                                                          
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </fieldset>
                                        </div>



                                    
                                    <div className="col-md-6" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>

                                        <fieldset className="form-group">
                                            <label className="form-label" for="exampleError">Estoque para</label>
                                            <input type="text" className="form-control" value={autonomiaDiaria} style={{ backgroundColor: '#e4f5f7'}} disabled
                                            />

                                        </fieldset>

                                    </div>

                                    <div className="col-md-6" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>

                                        <fieldset className="form-group">
                                            <label className="form-label" for="exampleError">Autonomia mensal</label>
                                            <input type="text" className="form-control" value={autonomia} style={{ backgroundColor: '#e4f5f7'}} disabled
                                            />

                                        </fieldset>

                                    </div>




                                        <div className="col-md-12" style={{ display: 'none' }} >

                                            <fieldset className="form-group">

                                                <label className="form-label" >Entregas</label>

                                                <table className="table table-striped table-bordered table-condensed">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Data entrega</div></th>
                                                            <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Quantidade</div></th>
                                                            
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    
                                                        {resultadoEntregas}

                                                    </tbody>

                                                
                                                    
                                                </table>

                                            </fieldset>

                                            


                                        </div>

                                    <center>
                                    <button type="button" className="btn btn-inline btn-danger" onClick={() => geraPDF()} style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>PDF</button>
                                    </center>
    
                        
                        
                        </div>      


                    
                              
                </div>

                
                
	
	        
            </div>



            
            <ModalAlteracoesMeta item_id={item_id} ano={ano} />


        </div>

    )

}

export default Item