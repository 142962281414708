import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import moment from 'moment'
import ModalDownloadAdm from '../modal/ModalDownloadAdm'



const DownloadsAdm = () => {



    const anoAtual = moment().format('YYYY')
    const navigate = useNavigate()

    const [download_id, setDownload_id] = useState()

    const [categoria, setCategoria] = useState('')
    const [resultadoCategoria, setResultadoCategoria] = useState([])
    const [listaAnos, setListaAnos] = useState()
    const [ano, setAno] = useState(anoAtual)
    const [oculto, setOculto] = useState()

    const carregaAnos = () => {

        var x_array = []

        for (var x = 2024; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }




    const consulta = () => {

        var dataPost = {

            ano: ano,
            categoria: categoria,
            oculto: oculto,

        }

        //console.log(dataPost)

        api.post('downloadLista', dataPost).then((result) => {

            //console.log(result.data)

            setResultado(result.data)

            /*

            setResultado(result.data.map((rs) => {

                return {
                    
                    cpf: rs.cpf,
                    usuario_id: rs.usuario_id,
                    nome: rs.nome,
                    email: rs.email,
                    numeroAcessos: rs.numeroAcessos,
                    deletado: rs.deletado == true ? 'Sim' : 'Não',
                    ultimoAcesso: rs.ultimoAcesso,
                    perfil: rs.perfil,
                }


            }))
            */

        }).catch((err) => {

            console.log(err.response)

        })


    }




    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [
          {
            accessorKey: 'ad_upd', //access nested data with dot notation
            header: 'Data atualização',
           
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
           
          },
          
          {
            accessorKey: 'categoria', //normal accessorKey
            header: 'Categoria',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          /*
          {
            accessorKey: 'usuario', //normal accessorKey
            header: 'Por',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          */
          {
            accessorKey: 'oculto', //normal accessorKey
            header: 'Oculto',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
              Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == true ? 'danger' : 'success'  }`}>{renderedCellValue == true ? 'Sim' : 'Não'}</span>
           
            ),
          },

          {
            accessorKey: 'faj', //normal accessorKey
            header: 'FAJ',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
              Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == true ? 'info' : ''  }`}>{renderedCellValue == true ? 'X' : ''}</span>
           
            ),
          },
          
          {
            accessorKey: 'dante', //normal accessorKey
            header: 'Dante',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
              Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == true ? 'warning' : ''  }`}>{renderedCellValue == true ? 'X' : ''}</span>
           
            ),
          },
          
        ],
        [],
      );



    const carregaCategorias = () => {


        api.post('categoriasDownload').then((result) => {

            //console.log(result.data)
            setResultadoCategoria(result.data.map((rs) =>
            
                <option value={rs.categoria}>{rs.categoria}</option>
            
            ))
            

        }).catch((err) => {

            console.log(err.response)

        })


    }



    useEffect(() => {

        carregaCategorias()        
        carregaAnos()


    }, [])


    useEffect(() => {

        consulta()

    }, [ano, categoria, oculto])



    return (

        <div>

            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-file"></i></a>
                            </li>
                            <li>
                                Downloads - Administração
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div className="col-md-3 col-sm-6">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError">Ano</label>
                                <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                    {listaAnos}
                                </select>

                            </fieldset>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError">Tipo</label>
                                <select type="text" className="form-control" value={oculto} onChange={event => setOculto(event.target.value)}>
                                    <option value={false}>Arquivos disponíveis</option>
                                    <option value={true}>Arquivos ocultos</option>
                                </select>

                            </fieldset>
                        </div>
                        
                        <div className="col-md-6 col-sm-6">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError">Categoria</label>
                                <select type="text" className="form-control" value={categoria} onChange={event => setCategoria(event.target.value)}>
                                    <option value="">[Selecione]</option>
                                    {resultadoCategoria}
                                </select>

                            </fieldset>
                        </div>
                        
                        <div class="col-sm-12 col-md-12">
            
            
                                                                    
                            <button type="button" class="btn btn-inline btn-success" onClick={() => {window.$('#modalDownloadAdm').modal('show');setDownload_id()}}>+ Arquivo</button>
                        
                            <br/><br/>

                
                
                            <MaterialReactTable 
                                
                                columns={columns} 
                                data={resultado} 
                                localization={MRT_Localization_PT_BR}
                                //rowsPerPage={20}
                                //options={options}
                                initialState={{ density: 'compact' }}
                                muiTableHeadCellProps={{
                                    //easier way to create media queries, no useMediaQuery hook needed.
                                    sx: {
                                    fontSize: {
                                        xs: '8px',
                                        sm: '9px',
                                        md: '10px',
                                        lg: '11px',
                                        xl: '12px',
                                    },
                                    },
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                        fontSize: {
                                        xs: '8px',
                                        sm: '9px',
                                        md: '10px',
                                        lg: '11px',
                                        xl: '12px',
                                        },
                                    },
                                }}
                
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                    //console.info(event, row.original.usuario_id);
                                    //window.open(`${process.env.REACT_APP_API}/upload/arquivos/${row.original.arquivoNome}`)
                                    setDownload_id(row.original.download_id);window.$('#modalDownloadAdm').modal('show')
                
                                    },
                                    sx: {
                                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                })}
                                
                            />



                        </div>
                    </div>                
                </div>
            </div>


            <ModalDownloadAdm download_id={download_id} consulta={consulta} carregaCategorias={carregaCategorias} />


        </div>

    )

}


export default DownloadsAdm