import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMoney, sleep } from '../functions/generalFunctions';
import ModalCatalogo from '../modal/ModalCatalogo'

//import ModalCatalogo from '../modal/ModalCatalogo'


const Catalogo = () => {

    const [listaGrupos, setListaGrupos] = useState()
    const [grupo, setGrupo] = useState('')
    const [resultado, setResultado] = useState([])
    const [item_id, setItem_id] = useState()
    const [prioridade, setPrioridade] = useState(false)
    const [padronizado, setPadronizado] = useState(false)

    const perfil = sessionStorage.getItem('perfil')
    const somenteLeitura = sessionStorage.getItem('somenteLeitura')


    const carregaGrupo = () => {

        api.get('gruposItens').then((result) => {

            const data = result.data

            setListaGrupos(data.map((rs, key) => 
            
                <option key={rs.key} value={rs.grupo}>
                    {rs.grupo}
                </option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }



    const columns = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao',
            header: 'Siafisico Pregão',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

          {
            accessorKey: 'codigoMestreMV', //access nested data with dot notation
            header: 'Cód. Mestre MV',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

          {
            accessorKey: 'codigoFilhoMV', //access nested data with dot notation
            header: 'Cód. Filho MV',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

         
          {
            accessorKey: 'descricao',
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'unidFornecimento',
            header: 'Unidade',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'unidTempoConsumo',
            header: 'Unid. Tempo Consumo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          
          {
            accessorKey: 'grupo',
            header: 'Grupo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'prioridade', //normal accessorKey
            header: 'Crítico',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
              Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == true ? 'danger' : ''  }`}>{renderedCellValue == true ? 'X' : ''}</span>
           
            ),
          },
         /*
          {
            accessorKey: 'planejado',
            header: 'Planejado',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          */
        ],
        [],
      );


    const carregaItens = () => {

        if (grupo != null) {

            var dataPost = {

                grupo: grupo,
                prioridade: prioridade,
                padronizado: padronizado,
    
            }
    
    
            api.post('itens', dataPost).then((result) => {
    
    
                const data = result.data
                //console.log(data)

                setResultado(result.data)
                
                /*
                setResultado(data.map((rs) => {
    
    
                    return {
    
                        item_id: rs.item_id,
                        //codigoMestreMV: rs.codigoMestreMV,
                        siafisicoPregao: rs.siafisicoPregao,
                        //codigoFilhoMV: rs.codigoFilhoMV,
                        descricao: rs.descricao,
                        //codigoProtheus: rs.codigoProtheus,
                        unidFornecimento: rs.unidFornecimento,
                        unidTempoConsumo: rs.unidTempoConsumo,
                        grupo: rs.grupo,
                        //subGrupo: rs.subGrupo,
                        //custoMedio: rs.custoMedio != null ? fmtMoney(rs.custoMedio, 2) : '',
                        //planejado: rs.planejado == true ? 'Sim' : 'Não'
    
    
                    }
    
    
                }))
                */
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })


        }

        


    }


  async function exportaExcel() {


            var dataPost = {

                grupo: grupo,
                prioridade: prioridade,
                padronizado: padronizado,
                
            }
            
            //console.log(dataPost)

          await api.post('exportaExcelTipo', dataPost).then((result) => {
      
            //console.log(result.data)

            if (result.data.status == 'ok') {

              window.toastr.info('Aguarde, gerando XLSX...')

                sleep(3000).then(() => {

                    //console.log(result.data[0].fileNameCompleto, '!!')
                    //window.location.href = result.data.arquivo
                    //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                    //window.location.href = `https://apiportaltransp.fajsaude.com.br/${result.data.arquivo}`
                    window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                    
                    //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
                })
                

            }

        }).catch((err) => {

            console.log(err.response)

        })


    }



    useEffect(() => {

        carregaGrupo()

    }, [])


    useEffect(() => {

        carregaItens()

    }, [grupo, prioridade, padronizado])


    return (

        <div>

            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                                Catálogo
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
        
                                


                                <button type="button" class="btn btn-inline btn-primary" onClick={() => {window.$('.modal-CatalogoEdit').modal('show');setItem_id()}} disabled={ perfil == 'Administrador' || somenteLeitura == 'true' ? false : true}>+ Novo</button>&nbsp;
                                <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} >Exportar Excel</button>
                                
                                        
                                <br/><br/>

                                <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                        <fieldset className="form-group">
                                            <label className="form-label" for="exampleError">Grupo</label>
                                            <select type="text" className="form-control" value={grupo} onChange={event => setGrupo(event.target.value)}>
                                                <option value="">Selecione</option>
                                                {listaGrupos}
                                            </select>
                                            
                                        </fieldset>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <fieldset className="form-group">
                                            <label className="form-label" for="exampleError">Itens críticos</label>
                                            <select type="text" className="form-control" value={prioridade} onChange={event => setPrioridade(event.target.value)}>
                                                <option value={false}>Todos os itens</option>
                                                <option value={true}>Somente itens críticos</option>
                                            </select>
                                            
                                        </fieldset>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <fieldset className="form-group">
                                            <label className="form-label" for="exampleError">Padronizado</label>
                                            <select type="text" className="form-control" value={padronizado} onChange={event => setPadronizado(event.target.value)}>
                                                <option value={false}>Todos os itens</option>
                                                <option value={true}>Somente itens padronizados</option>
                                            </select>
                                            
                                        </fieldset>
                                    </div>

                                </div>



                                <MaterialReactTable 
                                    
                                    columns={columns} 
                                    data={resultado} 
                                    localization={MRT_Localization_PT_BR}
                                    //rowsPerPage={20}
                                    //options={options}
                                    initialState={{ density: 'compact' }}
                                    muiTableHeadCellProps={{
                                        //easier way to create media queries, no useMediaQuery hook needed.
                                        sx: {
                                        fontSize: {
                                            xs: '8px',
                                            sm: '9px',
                                            md: '10px',
                                            lg: '11px',
                                            xl: '12px',
                                        },
                                        },
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            fontSize: {
                                            xs: '8px',
                                            sm: '9px',
                                            md: '10px',
                                            lg: '11px',
                                            xl: '12px',
                                            },
                                        },
                                    }}

                                    muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                        //console.info(event, row.original.usuario_id);
                                        //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                        window.$('.modal-CatalogoEdit').modal('show');setItem_id(row.original.item_id)

                                    },
                                    sx: {
                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                    })}
                                    
                                />




                            <ModalCatalogo item_id={item_id} carregaItens={carregaItens} carregaGrupo={carregaGrupo} />


                        </div>
                    </div>                
                </div>
            </div>


            
        

        </div>
    )


}

export default Catalogo