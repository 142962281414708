import { useState, useEffect } from "react"
import api from "../components/api"


const Modal = (props) => {


    const slug = props.mensagem_id

    const usuario_id = sessionStorage.getItem('usuario_id')

    const [mensagem_id, setMensagem_id] = useState()
    const [assunto, setAssunto] = useState('')
    const [texto, setTexto] = useState('')

    const [faj, setFaj] = useState(false) 
    const [dante, setDante] = useState(true)


    useEffect(() => {

        limpa()


        if (slug) {

            setMensagem_id(slug)
            carrega(slug)

        }
        

    }, [slug])


    const limpa = () => {

        setMensagem_id()
        setAssunto('')
        setTexto('')        
        setDante(true)
        setFaj(false)

    }

    const carrega = (mensagem_id) => {

        api.get(`mensagem/${mensagem_id}`).then((result) => {

            //console.log(result.data)
            
            var data = result.data[0]

            setAssunto(data.assunto)
            setTexto(data.texto)
            setDante(data.dante)
            setFaj(data.faj)


        }).catch((err) => {

            console.log(err.response)

        })

    }

    const salva = () => {


        var dataPost = {

            assunto: assunto,
            texto: texto,
            faj: faj,
            dante: dante,
            ad_usr: usuario_id,

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {


                    if (mensagem_id) {

                        api.put(`mensagem/${mensagem_id}`, dataPost).then(() => {

                            window.toastr.success('Registro salvo com sucesso')
                            props.consulta()

                        }).catch((err) => {

                            console.log(err.response)

                        })

                    } else {


                        api.post('mensagem', dataPost).then((result) => {

                            var data = result.data[0]

                            window.toastr.success('Registro salvo com sucesso')
                            setMensagem_id(data.mensagem_id)
                            props.consulta()

                        }).catch((err) => {

                            console.log(err.response)

                        })


                    }

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }


    const apaga = () => {



        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar?',
		    buttons: {
		        Sim: function () {


                    api.delete(`mensagem/${mensagem_id}`).then(() => {

                        window.$('#modalMensagemEdit').modal('hide')
                        window.toastr.success('Mensagem apagada com sucesso')
                        limpa()
                        props.consulta()

                    }).catch((err) => {

                        console.log(err.response)

                    })

                    
                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }


    return (

        <div>

            <div className={`modal fade modal-MensagemEdit`}
                    id="modalMensagemEdit"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Mensagem {mensagem_id}</h4>
                        </div>
                        <div class="modal-body">

                            <div className="row">

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Assunto</label>
                                        <input type="text" className="form-control" maxLength={50} value={assunto} onChange={event => setAssunto(event.target.value)} />
                                                        
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Texto</label>
                                        <textarea type="text" rows={5} className="form-control" maxLength={2000} value={texto} onChange={event => setTexto(event.target.value)} />
                                                        
                                    </div>
                                </div>


                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mensagem Fundação Adib Jatene</label>
                                        <select className="form-control" value={faj} onChange={event => setFaj(event.target.value)} >

                                            <option value="">[Selecione]</option>
                                            <option value={false}>Não</option>
                                            <option value={true}>Sim</option>

                                        </select>
                                                    

                                                        
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mensagem Dante Pazzanese</label>
                                        <select className="form-control" value={dante} onChange={event => setDante(event.target.value)} >

                                            <option value="">[Selecione]</option>
                                            <option value={false}>Não</option>
                                            <option value={true}>Sim</option>

                                        </select>
                                                    

                                                        
                                    </div>
                                </div>
                           
                            </div>

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>

                            <button type="button" className="btn btn-inline btn-danger" onClick={() => apaga()} style={{ display: mensagem_id ? 'table-row' : 'none' }} >Apagar</button>
                            <button type="button" className="btn btn-inline btn-success" onClick={() => salva()}  >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>



        </div>

    )

}

export default Modal