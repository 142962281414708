import { useEffect, useState } from "react"
import api from "../components/api"


const Modal = (props) => {

    const item_id = props.item_id
    const ano = props.ano
    const [resultado, setResultado] = useState([])

    const [descricao, setDescricao] = useState('')
    const [siafisicoPregao, setSiafisicoPregao] = useState('')


    const carrega = () => {


        if (item_id && ano) {


            var dataPost = {

                item_id: item_id,
                ano: ano,

            }


            api.post('planejamentoCorrigidoLista', dataPost).then((result) => {

                console.log(result.data)

                if (result.data.length > 0) {

                    setSiafisicoPregao(result.data[0].siafisicoPregao)
                    setDescricao(result.data[0].descricao)

                    setResultado(result.data.map((rs) => 
                    
                        <tr>

                            <td style={{ textAlign: 'center'}}>
                                {rs.dataAlteracao}
                            </td>
                            <td style={{ textAlign: 'right'}}>
                                {rs.metaAnterior}
                            </td>
                            <td style={{ textAlign: 'right'}}>
                                {rs.metaCorrigida}
                            </td>
                            <td style={{ textAlign: 'right'}}>
                                {rs.percentual}%
                            </td>
                            <td style={{ textAlign: 'center'}}>
                                {rs.nome}
                            </td>

                        </tr>
                    
                    ))

                }

                

            }).catch((err) => {

                console.log(err)


            })


        }


    }


    const limpa = () => {


        setSiafisicoPregao('')
        setDescricao('')
        setResultado([])


    }


    useEffect(() => {

        limpa()
        carrega()        
        

    }, [item_id, ano])


    return (


        <div>

                <div className={`modal fade modal-AlteracoesMeta`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Histórico de alterações da meta anual</h4>
                        </div>
                        <div class="modal-body">

                            <div className="row" style={{ display: siafisicoPregao != '' && siafisicoPregao != null ? 'table-row' : 'none' }}>

                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Siafisico</label>
                                        <input type="text" className="form-control" value={siafisicoPregao} disabled={true} />
                                    </fieldset>
                                </div>

                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Ano</label>
                                        <input type="text" className="form-control" value={ano} disabled={true} />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Confirma nova senha *</label>
                                        <textarea className="form-control" value={descricao} disabled={true} rows="3" />
                                            
                                        
                                    </fieldset>
                                </div>
                                <div className="col-md-12">

                                    <table className="table table-striped table-bordered table-condensed">
                                        <thead>
                                            <tr>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Data alteração</div></th>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}}><div>Meta anterior</div></th>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Meta corrigida</div></th>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>%</div></th>
                                                <th style={{ fontSize: '11px', textAlign: 'center'}} align="center"><div>Por</div></th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                        
                                            {resultado}

                                        </tbody>
                                    </table>

                                </div>

                            </div>

                            <div className="row" style={{ display: siafisicoPregao == '' ? 'table-row' : 'none' }}>
                            
                                <div className="col-md-12" style={{ textAlign: 'center'}}>

                                    Não foram encontradas alterações para este item em {ano}
                                    
                                </div>


                            </div>




                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )

}

export default Modal