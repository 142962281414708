import { useEffect, useState } from 'react'
import PlanejConsumoDetalhe from '../componentsPages/PlanejConsumoDetalhe'
import PlanejConsumoConsumo from '../componentsPages/PlanejConsumoConsumo'
import api from '../components/api'

const Modal = (props) => {

    const perfil = sessionStorage.getItem('perfil')
    
    const planejamento_id = props.planejamento_id
    const item_id = props.item_id
    
    const [totalConsumo, setTotalConsumo] = useState(0)



    const carregaTotalConsumo = () => {

        if (item_id) {

            api.get(`empenhoItemTotal/${item_id}`).then((result) => {

                var data = result.data[0]
                setTotalConsumo(data.total)

            }).catch((err) => {

                console.log(err.response)

            })

        }

    }


    useEffect(() => {

        window.$('[href="#tabs-4-tab-1k2"]').tab('show');
        carregaTotalConsumo()

    }, [planejamento_id])

    return (

        <div>

            <div class='modal fade modal-PlanejConsumoEdit'
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                                <button style={{ float: 'right'}} type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                    x
                                </button>
                            <h4 class="modal-title" id="myModalLabel">Planejamento - Item {planejamento_id}</h4>
                        </div>
                        <div class="modal-body">




                                        <PlanejConsumoDetalhe planejamento_id={planejamento_id} carregaPlanej={props.carregaPlanej} />



                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            
                            <button type="button" className="btn btn-inline btn-primary"  data-dismiss="modal">Fechar</button>
                            
                            
                            
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    )

}

export default Modal