import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import moment from 'moment'
import ModalMensagemEdit from '../modal/ModalMensagemEdit'


const MensagensAdm = () => {


    const [resultado, setResultado] = useState([])
    const anoAtual = moment().format('YYYY')
    const navigate = useNavigate()
    const [listaAnos, setListaAnos] = useState()
    const [ano, setAno] = useState(anoAtual)

    const [mensagem_id, setMensagem_id] = useState()


    const columns = useMemo(
        () => [
          {
            accessorKey: 'ad_new', //access nested data with dot notation
            header: 'Data',
           
          },
          {
            accessorKey: 'assunto', //access nested data with dot notation
            header: 'Assunto',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
           
          },
         

          {
            accessorKey: 'faj', //normal accessorKey
            header: 'FAJ',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
              Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == true ? 'info' : ''  }`}>{renderedCellValue == true ? 'X' : ''}</span>
           
            ),
          },
          
          {
            accessorKey: 'dante', //normal accessorKey
            header: 'Dante',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
              Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == true ? 'warning' : ''  }`}>{renderedCellValue == true ? 'X' : ''}</span>
           
            ),
          },
          
        ],
        [],
      );




    const carregaAnos = () => {

        var x_array = []

        for (var x = 2024; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }


    const consulta = () => {

        var dataPost = {

            ano: ano,

        }

        api.post('mensagens', dataPost).then((result) => {


            setResultado(result.data)


        }).catch((err) => {

            console.log(err.response)

        })


    }



    useEffect(() => {

        carregaAnos()

    }, [])



    useEffect(() => {

        consulta()

    }, [ano])


    return (

        <div>


            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-file"></i></a>
                            </li>
                            <li>
                                Mensagens - Administração
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div className="col-md-3 col-sm-6">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError">Ano</label>
                                <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                    {listaAnos}
                                </select>

                            </fieldset>
                        </div>
                        
                        
                        <div class="col-sm-12 col-md-12">
            
            
                                                                    
                            <button type="button" class="btn btn-inline btn-success" onClick={() => {window.$('#modalMensagemEdit').modal('show');setMensagem_id()}}>+ Mensagem</button>
                        
                            <br/><br/>

                
                
                            <MaterialReactTable 
                                
                                columns={columns} 
                                data={resultado} 
                                localization={MRT_Localization_PT_BR}
                                //rowsPerPage={20}
                                //options={options}
                                initialState={{ density: 'compact' }}
                                muiTableHeadCellProps={{
                                    //easier way to create media queries, no useMediaQuery hook needed.
                                    sx: {
                                    fontSize: {
                                        xs: '8px',
                                        sm: '9px',
                                        md: '10px',
                                        lg: '11px',
                                        xl: '12px',
                                    },
                                    },
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                        fontSize: {
                                        xs: '8px',
                                        sm: '9px',
                                        md: '10px',
                                        lg: '11px',
                                        xl: '12px',
                                        },
                                    },
                                }}
                
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                    //console.info(event, row.original.usuario_id);
                                    //window.open(`${process.env.REACT_APP_API}/upload/arquivos/${row.original.arquivoNome}`)
                                    setMensagem_id(row.original.mensagem_id);window.$('#modalMensagemEdit').modal('show')
                
                                    },
                                    sx: {
                                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                })}
                                
                            />



                        </div>
                    </div>                
                </div>
            </div>


            <ModalMensagemEdit mensagem_id={mensagem_id} consulta={consulta} />

        </div>

    )

}


export default MensagensAdm