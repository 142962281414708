import { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMes, fmtMoney, sleep } from '../functions/generalFunctions';
import moment from 'moment'

import ModalEntregaItem from '../modal/ModalEntregaItem'


//import ModalEmpenho from '../modal/ModalEmpenho'


const Entregas = () => {

    const navigate = useNavigate()

    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()   
    const [ano, setAno] = useState(anoAtual.toString())
    const [mes, setMes] = useState('')
    const [listaMeses, setListaMeses] = useState()

    
    const [resultado, setResultado] = useState([])
    const [entrega_id, setEntrega_id] = useState()
    
    const [item_id, setItem_id] = useState()


    const carregaAnos = () => {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }



    
    async function exportaExcel() {

      var dataPost = {

        ano: ano,
        mes: mes,

      }

      await api.post(`exportaExcelEntrega`, dataPost).then((result) => {

        

        if (result.data.status == 'ok') {

          window.toastr.info('Aguarde, gerando XLSX...')

            sleep(2000).then(() => {

                //console.log(result.data[0].fileNameCompleto, '!!')
                //window.location.href = result.data.arquivo
                //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                window.location.href = `https://apiportaltransp.fajsaude.com.br/${result.data.arquivo}`
                //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
            })
            

        }

    }).catch((err) => {

        console.log(err.response)

    })


  }




    const carregaMeses = () => {

      var x_array = []

      for (var x = 1; x <= 12; x++) {

          x_array.push(x)

      }

      setListaMeses(x_array.map((mes) => 
      
          <option value={mes}>{fmtMes(mes)}</option>
      
      ))


  }


    const columns = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao', //access nested data with dot notation
            header: 'Siafisico',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'empenho',
            header: 'Empenho',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'dataEntrega', //normal accessorKey
            header: 'Entrega em',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          
          
        ],
        [],
      );


      const carregaEntregas = () => {

        var dataPost = {

          ano: ano,
          mes: mes,

        }

    
        api.post('entregasLista', dataPost).then((result) => {


            const data = result.data
            //console.log(data)
            

            setResultado(data.map((rs) => {


                return {

                    item_id: rs.item_id,
                    empenho_id: rs.empenho_id,
                    siafisicoPregao: rs.siafisicoPregao,
                    empenho: rs.empenho,
                    descricao: rs.descricao,
                    dataEntrega: rs.dataEntrega,
                    nl: rs.nl,
                    quantidade: rs.quantidade,
                    

                }


            }))

        }).catch((err) => {

            console.log(err.response)

        })


    }


    useEffect(() => {

      carregaAnos()
      carregaMeses()

    }, [])


    useEffect(() => {
        
        carregaEntregas()
        

    }, [ano, mes])


    return (

        <div>

            <div id="contentwrapper">
                <div class="main_content">
                    <div id="jCrumbs" class="breadCrumb module">
                        <ul>
                            <li>
                                <a href="#"><i class="glyphicon glyphicon-home"></i></a>
                            </li>
                            <li>
                            Entregas
                            </li>
                            
                        </ul>
                    </div>	
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
        


                        <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} >Exportar Excel</button>
                        &nbsp;
                        <button type="button" class="btn btn-inline btn-primary" onClick={() => navigate('/Entrega')} >Lançar entrega</button>
                                
                        <br/><br/>


                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <fieldset className="form-group">
                                    <label className="form-label" for="exampleError">Ano</label>
                                    <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                        {listaAnos}
                                    </select>

                                </fieldset>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <fieldset className="form-group">
                                    <label className="form-label" for="exampleError2">Mês</label>
                                    <select type="text" className="form-control" value={mes} onChange={event => setMes(event.target.value)}>
                                        <option value="">[Todos]</option>
                                        {listaMeses}
                                    </select>
                                </fieldset>
                            </div>
                        

                        </div>



                        <MaterialReactTable 
                            
                            columns={columns} 
                            data={resultado} 
                            localization={MRT_Localization_PT_BR}
                            //rowsPerPage={20}
                            //options={options}
                            initialState={{ density: 'compact' }}
                            muiTableHeadCellProps={{
                                //easier way to create media queries, no useMediaQuery hook needed.
                                sx: {
                                fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                },
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                    },
                                },
                            }}

                            muiTableBodyRowProps={({ row }) => ({
                            onClick: (event) => {
                                //console.info(event, row.original.usuario_id);
                                //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                //window.$('.modal-EntregaItem').modal('show');setEntrega_id(row.original.entrega_id)

                            },
                            sx: {
                                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                            },
                            })}
                            
                        />








                        </div>
                    </div>                
                </div>
            </div>


            <ModalEntregaItem entrega_id={entrega_id} carregaEntregas={carregaEntregas} />
            

        </div>
    )


}

export default Entregas
